import Icon from "@mdi/react";
import {
  mdiMapMarkerRadius,
  mdiMonitorDashboard,
  mdiCalculatorVariantOutline,
  mdiFinance,
  mdiAccountWrench,
} from '@mdi/js';

import {
  Gradient as GradientIcon,
  LibraryBooks as LibraryBooksIcon,
  SyncAlt as SyncAltIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
  Extension as ExtensionIcon,
  AddAlert as AddAlertIcon,
} from '@mui/icons-material';

import {
  mdiFileDocumentCheck,
  mdiCog,
} from '@mdi/js';

import ApiIcon from '@mui/icons-material/Api';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {
  VideojetConnectDesign,
  VideojetConnectDesignNew,
  VideojetConnectExchange,
  VideojetConnectInsight,
  VideojetConnectRemoteService,
} from './constants/global';
import Utils, { isExternalNormalUserOrAdminOrMaintenance } from "./utilities/utils";


interface NavigationItem {
  title: string;
  segment: string;
  icon?: JSX.Element;
  children?: NavigationItem[];
  hidden?: boolean;
  siteModule?: string;
  extraModule?: string;
  hideIfModuleNotEnabled?: boolean;
  hideIfNotEnabled?: boolean;
  showAlways?: boolean;
  requireAdmin?: boolean;
  requireSite?: boolean;
}

const standardVrsModules = { siteModule: 'vrs', extraModule: 'vrsOperations', hideIfModuleNotEnabled: true, hideIfNotEnabled: true };
const standardInsightModules = { siteModule: 'Intelligence', hideIfModuleNotEnabled: true, hideIfNotEnabled: true };
const standardApiModules = { siteModule: 'api', hideIfModuleNotEnabled: true, hideIfNotEnabled: true };


export const getBaseNavigation = (_T, selectedSiteId, isAdmin, isUserConfigImporter): NavigationItem[] => [
  {
    title: VideojetConnectRemoteService,
    segment: 'vrs',
    showAlways: true,
    children: [
      { ...standardVrsModules, title: _T('Summary'), segment: 'summary', icon: <AddAlertIcon /> },
      { ...standardVrsModules, title: _T('Site Dashboard'), segment: 'sitedashboard', icon: <Icon path={mdiMonitorDashboard} size={1} /> },
      { ...standardVrsModules, title: _T('Site Map'), segment: 'sitemap', icon: <Icon path={mdiMapMarkerRadius} size={1} /> },
      { ...standardVrsModules, title: _T('Availability'), segment: 'availability', icon: <Icon path={mdiCalculatorVariantOutline} size={1} /> },
      { ...standardVrsModules, title: _T('Production'), segment: 'production', icon: <Icon path={mdiFinance} size={1} /> },
      { ...standardVrsModules, title: _T('Service Dashboard'), segment: 'servicedashboard', icon: <Icon path={mdiAccountWrench} size={1} /> },
      {
        title: _T('Reports'), segment: 'reports', icon: <Icon path={mdiFileDocumentCheck} size={1} />,
        children: [
          { ...standardVrsModules, title: _T('Printer Snapshot'), segment: 'snapshot' },
          { ...standardVrsModules, title: _T('Printer Performance'), segment: 'performance' },
          { ...standardVrsModules, title: _T('Data Download'), segment: 'datadownload' },
          { ...standardVrsModules, title: _T('Rapid Recovery'), segment: 'rapidrecovery' },
          { ...standardVrsModules, title: _T('Consumable Usage'), segment: 'consumables' },
          { ...standardVrsModules, title: _T('Printer Needing Attention'), segment: 'unitrequiringattention' },
          { ...standardVrsModules, title: _T('VRS Usage'), segment: 'usage' },
        ],
      },
    ],
  },
  {
    title: VideojetConnectExchange,
    segment: 'api',
    siteModule: 'api',
    children: [
      { ...standardApiModules, title: _T('Getting Started'), segment: 'gettingstarted', icon: <InfoOutlinedIcon /> },
      { ...standardApiModules, title: _T('REST API Explorer'), segment: 'explorer', icon: <ApiIcon /> },
    ],
  },
  {
    title: VideojetConnectInsight,
    segment: 'insight',
    siteModule: 'Intelligence',
    children: [
      { ...standardInsightModules, title: _T('Performance'), segment: `site/${selectedSiteId}/lines/overview`, icon: <GradientIcon /> },
      { ...standardInsightModules, title: _T('Reports'), segment: `site/${selectedSiteId}/report/oee`, icon: <LibraryBooksIcon /> },
    ],
  },
  {
    title: VideojetConnectDesign,
    segment: 'design',
    siteModule: 'Design',
  },
  {
    title: VideojetConnectDesignNew,
    segment: 'new-design',
    siteModule: 'Design',
    hidden: !(isAdmin || isUserConfigImporter || (Utils.isDev() && isExternalNormalUserOrAdminOrMaintenance())),
  },
  {
    title: _T("Configuration"),
    segment: 'configuration',
    children: [
      {
        title: _T('Remote Service'), segment: 'remoteservice', icon: <Icon path={mdiCog} size={1} />,
        ...standardVrsModules,
        children: [
          { ...standardVrsModules, title: _T('Users'), segment: 'users' },
          { ...standardVrsModules, title: _T('Favorites'), segment: 'favorites' },
          { ...standardVrsModules, title: _T('Companies'), segment: 'companymanagement' },
          { ...standardVrsModules, title: _T('Sites'), segment: 'sitemanagement' },
          { ...standardVrsModules, title: _T("Res's"), segment: 'resmanagement' },
          { ...standardVrsModules, title: _T('Devices'), segment: 'devicemanagement' },
          { ...standardVrsModules, title: _T('Alerts'), segment: 'alertmanagement' },
          { ...standardVrsModules, title: _T('Notification Logs'), segment: 'notificationlog' },
          { ...standardVrsModules, title: _T('Data Tags'), segment: 'datatags' },
          { ...standardVrsModules, title: _T('Events'), segment: 'events' },
          { ...standardVrsModules, title: _T('SF Articles'), segment: 'salesforcearticles' },
          { ...standardVrsModules, title: _T('Device Logs'), segment: 'logmanagement' },
          { ...standardVrsModules, title: _T('Site Tags'), segment: 'sitetags' },
          { ...standardVrsModules, title: _T('Work Schedule'), segment: 'workschedule' },
        ],
      },
      {
        title: _T('Insight'), segment: 'insight', icon: <Icon path={mdiCog} size={1} />,
        ...standardInsightModules,
        children: [
          { ...standardInsightModules, title: _T('Sites'), segment: 'sites' },
          { ...standardInsightModules, title: _T('Lines'), segment: `site/${selectedSiteId}/lines` },
          { ...standardInsightModules, title: _T('Products'), segment: `site/${selectedSiteId}/products` },
          { ...standardInsightModules, title: _T('Shifts'), segment: `site/${selectedSiteId}/shifts` },
          { ...standardInsightModules, title: _T('Downtimes'), segment: `site/${selectedSiteId}/downtimes/category` },
          { ...standardInsightModules, title: _T('Display Mode'), segment: `site/${selectedSiteId}/displaymode` },
          { ...standardInsightModules, title: _T('Roles'), segment: `site/${selectedSiteId}/roles` },
          { ...standardInsightModules, title: _T('UserAccess'), segment: 'company/users' },
        ],
      },
      {
        title: _T('Design'),
        segment: 'design',
        siteModule: 'Design',
        icon: <Icon path={mdiCog} size={1} />,
        hidden: !(isAdmin || isUserConfigImporter || (Utils.isDev() && isExternalNormalUserOrAdminOrMaintenance())),
        children: [
          { title: _T('Manage Logos'), segment: 'logos', siteModule: 'Design' },
          { title: _T('Manage Settings'), segment: 'settings', siteModule: 'Design' },
        ],
      },
    ],
  },
  {
    title: _T('Settings'),
    segment: 'admin',
    requireAdmin: true,
    hidden: !isAdmin,
    children: [
      { requireAdmin: true, requireSite: true, title: _T('Admin Site Configuration'), segment: `site/${selectedSiteId}/properties`, icon: <SupervisedUserCircleIcon /> },
      { requireAdmin: true, title: _T('Manage Translations'), segment: 'translations', icon: <SyncAltIcon /> },
      { requireAdmin: true, title: _T('Operations'), segment: 'operations', icon: <ExtensionIcon /> },
    ],
  },
];
