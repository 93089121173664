import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useBreadcrumb, BreadcrumbItem } from '../../context/BreadcrumbContext/BreadcrumbContext';
import { useVrsTranslationState } from '../../context/AppContext/AppContext';

interface NavigationItem {
  title?: string;
  path?: string;
  segment?: string;
  fullPath?: string;
  children?: NavigationItem[];
}

interface BreadcrumbGeneratorProps {
  navigation: NavigationItem[];
}

const BreadcrumbGenerator: React.FC<BreadcrumbGeneratorProps> = ({ navigation }) => {
  const location = useLocation();
  const { setBreadcrumbs, setPageTitle } = useBreadcrumb();
  const { _T } = useVrsTranslationState();

  useEffect(() => {
    const findMatchingPath = (
      items: NavigationItem[],
      currentPath: string,
      segments: string[],
      segmentIndex = 0,
      breadcrumbTrail: BreadcrumbItem[] = []
    ): { breadcrumbs: BreadcrumbItem[], matchedItem?: NavigationItem } => {
      if (!items || items.length === 0 || segmentIndex >= segments.length) {
        return { breadcrumbs: breadcrumbTrail };
      }

      const currentSegment = segments[segmentIndex];
      
      for (const item of items) {
        // Check if this item matches the current segment
        if (item.segment === currentSegment || 
            (item.path && item.path.endsWith(currentSegment))) {
          
          // Add this item to the breadcrumb trail
          const updatedTrail = [...breadcrumbTrail, { 
            text: _T(item.title || 'No Title'), 
            path: item.fullPath 
          }];
          
          // If we've matched all segments, return the trail and the matched item
          if (segmentIndex === segments.length - 1) {
            return { breadcrumbs: updatedTrail, matchedItem: item };
          }
          
          // Otherwise, continue matching with children
          if (item.children) {
            const result = findMatchingPath(
              item.children,
              currentPath,
              segments,
              segmentIndex + 1,
              updatedTrail
            );
            
            if (result.matchedItem) {
              return result;
            }
          }
        }
      }
      
      // If no match found at this level, return current trail
      return { breadcrumbs: breadcrumbTrail };
    };

    const generateBreadcrumbs = () => {
      // Skip if pathname is empty or just '/'
      if (!location.pathname || location.pathname === '/') {
        setBreadcrumbs([{ text: _T('Home'), path: '/' }]);
        setPageTitle(_T('Home'));
        return;
      }

      // Get path segments
      const pathSegments = location.pathname.split('/').filter(Boolean);
      
      // Always start with Home
      const initialBreadcrumbs = [{ text: _T('Home'), path: '/' }];
      
      // Find matching path in navigation structure
      const { breadcrumbs, matchedItem } = findMatchingPath(
        navigation, 
        location.pathname,
        pathSegments,
        0,
        initialBreadcrumbs
      );
      
      // Set breadcrumbs
      setBreadcrumbs(breadcrumbs);
      
      // Set page title from the last matched item or the last segment if no match
      if (matchedItem) {
        setPageTitle(_T(matchedItem.title || "No Title"));
      } else {
        // Use last segment as fallback title
        const lastSegment = pathSegments[pathSegments.length - 1];
        setPageTitle(_T(lastSegment.charAt(0).toUpperCase() + lastSegment.slice(1)));
      }
    };

    generateBreadcrumbs();
  }, [location.pathname, navigation, setBreadcrumbs, setPageTitle, _T]);

  return null; // This component doesn't render anything
};

export default BreadcrumbGenerator;
