import { createTheme } from "@mui/material";
import tinycolor from "tinycolor2";
import {
    myWhiteShade3,
    myWhite,
    myPrimarySelectedColor,
    myPrimaryColor,
    mySecondaryColor,
} from "./constants/colors";

const warning = "#FFC260";
const success = "#867a24";
const info = "#58bec7";

const lightenRate = 7.5;
const darkenRate = 15;

export const videojetConnectTheme = (dateLocale: object) => {
    return createTheme(
        {
            palette: {
                primary: {
                    main: myPrimaryColor,
                    light: tinycolor(myPrimaryColor)
                        .lighten(lightenRate)
                        .toHexString(),
                    dark: tinycolor(myPrimaryColor)
                        .darken(darkenRate)
                        .toHexString(),
                },
                secondary: {
                    main: mySecondaryColor,
                    light: tinycolor(mySecondaryColor)
                        .lighten(lightenRate)
                        .toHexString(),
                    dark: tinycolor(mySecondaryColor)
                        .darken(darkenRate)
                        .toHexString(),
                    contrastText: "#FFFFFF",
                },
                warning: {
                    main: warning,
                    light: tinycolor(warning)
                        .lighten(lightenRate)
                        .toHexString(),
                    dark: tinycolor(warning).darken(darkenRate).toHexString(),
                },
                success: {
                    main: success,
                    light: tinycolor(success)
                        .lighten(lightenRate)
                        .toHexString(),
                    dark: tinycolor(success).darken(darkenRate).toHexString(),
                },
                info: {
                    main: info,
                    light: tinycolor(info).lighten(lightenRate).toHexString(),
                    dark: tinycolor(info).darken(darkenRate).toHexString(),
                },
                text: {
                    primary: "#4A4A4A",
                    secondary: "#6E6E6E",
                },
                background: {
                    default: "#F6F7FF",
                },
            },
            typography: {
                fontFamily: "Poppins",
                h1: {
                    fontSize: "3rem",
                },
                h2: {
                    fontSize: "2rem",
                },
                h3: {
                    fontSize: "1.64rem",
                },
                h4: {
                    fontSize: "1.5rem",
                },
                h5: {
                    fontSize: "1.285rem",
                },
                h6: {
                    fontSize: "1.142rem",
                },
                body1: {
                    fontSize: "1.05rem",
                },
                body2: {
                    fontSize: "1rem",
                },
            },
            components: {
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            fontSize: "14px",
                        },
                        formControl: {
                            width: "100%",
                        },
                    },
                },
                MuiListItemIcon: {
                    styleOverrides: {
                        root: {
                            minWidth: "10px",
                        },
                    },
                },
                MuiInputLabel: {
                    styleOverrides: {
                        root: {
                            fontSize: "14px",
                        },
                        formControl: {
                            width: "100%",
                        },
                    },
                },
                MuiListItemText: {
                    styleOverrides: {
                        root: {
                            marginTop: 0,
                            marginBottom: "1px",
                        },
                    },
                },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            fontSize: "13px",
                            textTransform: "capitalize" as const,
                        },
                        containedPrimary: {
                            color: myWhite,
                        },
                    },
                },
                MuiBackdrop: {
                    styleOverrides: {
                        root: {
                            backgroundColor: "#4A4A4A1A",
                        },
                    },
                },
                MuiMenu: {
                    styleOverrides: {
                        paper: {
                            boxShadow:
                                "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
                        },
                    },
                },
                MuiFormLabel: {
                    styleOverrides: {
                        root: {
                            fontSize: "15px",
                            paddingRight: "5px",
                        },
                        asterisk: {
                            color: "#db3131",
                            "&$error": {
                                color: "#db3131",
                            },
                        },
                    },
                },
                MuiToolbar: {
                    styleOverrides: {
                        root: {
                            alignItems: "unset",
                            maxHeight: "84px",
                        },
                    },
                },
                MuiAccordionSummary: {
                    styleOverrides: {
                        root: {
                            minHeight: "unset !important",
                            margin: "0 !important",
                        },
                        content: {
                            minHeight: "unset !important",
                            margin: "0 !important",
                            "&$expanded": {
                                margin: "0 !important",
                            },
                        },
                    },
                },
                MuiAccordion: {
                    styleOverrides: {
                        root: {
                            minHeight: "unset !important",
                            margin: "0 !important",
                        },
                    },
                },
                MuiTooltip: {
                    styleOverrides: {
                        tooltip: {
                            fontSize: "10px",
                            backgroundColor: mySecondaryColor,
                            borderRadius: "8px",
                        },
                    },
                },
                MuiTab: {
                    styleOverrides: {
                        root: {
                            fontSize: "13px",
                            textTransform: "capitalize" as const,
                        },
                        labelIcon: {
                            minHeight: "36px",
                        },
                    },
                },
                MuiMenuItem: {
                    styleOverrides: {
                        root: {
                            padding: "5px",
                        },
                    },
                },
                MuiIconButton: {
                    styleOverrides: {
                        root: {
                            padding: "10px",
                        },
                    },
                },
                MuiPaginationItem: {
                    styleOverrides: {
                        page: {
                            "&$selected": {
                                backgroundColor: myPrimaryColor,
                                color: myWhite,
                                "&:hover": {
                                    backgroundColor: myPrimarySelectedColor,
                                },
                            },
                        },
                    },
                },
                MuiListItem: {
                    styleOverrides: {
                        root: {
                            paddingTop: "2px",
                            paddingBottom: "2px",
                            "&$selected": {
                                backgroundColor: `${myWhite}`,
                                "&:hover": {
                                    backgroundColor: myWhiteShade3,
                                },
                            },
                            "&:hover": {
                                backgroundColor: myWhiteShade3,
                            },
                        },
                    },
                },
                MuiTouchRipple: {
                    styleOverrides: {
                        child: {
                            backgroundColor: "white",
                        },
                    },
                },
                MuiTableRow: {
                    styleOverrides: {
                        root: {
                            height: 56,
                        },
                    },
                },
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            borderBottom: "none",
                        },
                        head: {
                            fontSize: "0.95rem",
                        },
                        body: {
                            fontSize: "0.95rem",
                        },
                    },
                },
                MuiTablePagination: {
                    styleOverrides: {
                        selectLabel: {
                            display: "flex !important",
                            justifyContent: "center",
                            alignItems: "center",
                            color: '#4a4a4a',
                        },
                        displayedRows: {
                            display: "flex !important",
                            justifyContent: "center",
                            alignItems: "center",
                            color: '#4a4a4a',
                        },
                        input: {
                            fontSize: "13px",
                            color: '#4a4a4a',
                        },
                        actions: {
                            display: "flex !important",
                            justifyContent: "center",
                            alignItems: "center",
                            color: '#4a4a4a',
                            '& .MuiIconButton-root': {
                                color: '#4a4a4a',
                            },
                        },
                    },
                },
                MuiAutocomplete: {
                    styleOverrides: {
                        inputRoot: {
                            paddingRight: "5px !important",
                        },
                    },
                },
            },
        },
        dateLocale
    );
};
