import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router";
import queryString from "query-string";
import { useToaster } from '../../context/ToasterContext/ToasterContext';
import {
  CssBaseline,
  Grid,
  Typography,
  CircularProgress,
  Fade,
  // Button
} from "@mui/material";

import * as Auth from 'aws-amplify/auth';

import {
  loginUser,
  useAppGlobalDispatch,
  useVrsTranslationState,
  useLogOut,
  useConfigState
} from "../../context/AppContext/AppContext";
import { useSessionStorage } from "../../context/SessionStorageContext/SessionStorageContext";
import { Logger } from "../../utilities/Logger/Logger";
import logo from "../../loginImages/my_login_logo.svg";
import { TermsDialog } from "../TermsDialog/TermsDialog";
import { NewPasswordDialog } from "../NewPasswordDialog/NewPasswordDialog";
import Utils from "../../utilities/utils";
import LoginEnterUsername from "./LoginEnterUsername";
import LoginEnterPassword from "./LoginEnterPassword";
import { Cookies, useCookies } from "react-cookie";
import { useVrsUserActions } from "../../actions/vrsUserActions";
import { useCompanyActions } from "../../actions/companyActions";
import { useNavigateToLink } from "../../actions/NavigateActions";
import { extractErrors } from "../../libs/getDynoErrors";
import { getCurrentUser } from "../../libs/authLib";
import { myRed, myWhite } from "../../constants/colors";
import loginImage from "../../loginImages/my_login_background.png";

import { styled, StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { videojetConnectTheme } from "../../Theme";

import config from "../../config";
import { callExternalApi } from "../../libs/apiLib";
import { ssoTokenProvider } from '../../utilities/SSOTokenProvider';
import jwtDecode from "jwt-decode";
import { configureForSSO, configureForStandardAuth } from "../../amplifyConfig";
import { EmptyItem } from "../Basic/EmptyItem/EmptyItem";
import { localeMap } from "../../layouts/LocalMap";

const RootGrid = styled(Grid)({
  height: '100vh',
});

const ImageGrid = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(${loginImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundColor:
    theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: myWhite,
  width: '100%',
  [theme.breakpoints.down('xs')]: {
    maxHeight: theme.spacing(30)
  }
}));

const StyledLogo = styled("img")(({ theme }) => ({
  minWidth: theme.spacing(75),
  [theme.breakpoints.down('md')]: {
    minWidth: theme.spacing(62.5),
  }
}));

const PaperContainer = styled("div")(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    height: '100%'
  }
}));

const StyledErrorTypography = styled(Typography)({
  textAlign: "center",
  color: myRed
});

const loginBreaks: any = {
  image: {
    lg: 8,
    md: 7,
    sm: 12,
    xs: 12,
  },
  login: {
    lg: 4,
    md: 5,
    sm: 12,
    xs: 12,
  },
};

const fallbackProfile = {
  Languages: [{
    LanguageId: 1,
    IetflanguageTag: "en"
  }],
  LanguageId: 1
};
enum LoginState {
  EnterUsername,
  EnterPassword,
}

const getDomain = () => {
  if (window.location.host.toLowerCase().includes("localhost")) {
    return "localhost";
  }

  if (window.location.host.toLowerCase().includes("videojetcloud.com")) {
    return "videojetcloud.com";
  }

  return "videojet.com";
};

const displayCognitoTriggerError = (error: any) =>
  `${error.payload.email} ${error.message}`;

function Login() {
  const toastr = useToaster();
  const navigate = useNavigateToLink();
  const location = useLocation();
  const logout = useLogOut();

  const [, setCookie, removeCookie] = useCookies(["ReturnUrlSearch"]);

  const inputReference = useRef<any>(null);

  const companyActions = useCompanyActions();

  const { token } = useParams<{ token: string }>();
  const { _T } = useVrsTranslationState();

  const vrsUserActions = useVrsUserActions();

  const queryStringObj = queryString.parse(location.search);
  const email: string = queryStringObj && queryStringObj.Email ? (queryStringObj.Email as string) : "";
  const code = (queryStringObj && queryStringObj.code) as string;

  const errorCode = (queryStringObj && queryStringObj.error_description) as string;
  const errorQs = (queryStringObj && queryStringObj.error) as string;
  const userSessionStorage = useSessionStorage();
  const userDispatch = useAppGlobalDispatch();
  const [error, setError] = useState(false);
  const [username, setUsername] = useState(email);
  const [password, setPassword] = useState("");

  const [authError, setAuthError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showNewPasswordModal, setShowNewPasswordModal] = useState(false);
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [termsContent, setTermsContent] = useState("");
  const [pendingLoginData, setPendingLoginData] = useState<ILoginResult | null>(null);

    // Create localStorage persistence for processed codes
    const getProcessedCodesFromStorage = () => {
      try {
        const storedCodes = localStorage.getItem('processed_oauth_codes');
        return storedCodes ? new Set(JSON.parse(storedCodes)) : new Set();
      } catch (e) {
        console.error('Error retrieving processed codes from storage:', e);
        return new Set();
      }
    };
  
    const saveProcessedCodesToStorage = (codes) => {
      try {
        localStorage.setItem('processed_oauth_codes', JSON.stringify([...codes]));
      } catch (e) {
        console.error('Error saving processed codes to storage:', e);
      }
    };
    
    const clearProcessedCodesFromStorage = () => {
      try {
        localStorage.removeItem('processed_oauth_codes');
        processedCodes.current.clear();
        Logger.of("Login").info("Cleared processed OAuth codes from storage");
      } catch (e) {
        console.error('Error clearing processed codes from storage:', e);
      }
    };
  
  
  // local
    // Use ref to keep track of processed codes in memory with initial value from localStorage
  const processedCodes = useRef(getProcessedCodesFromStorage());

  const [isLoading, setIsLoading] = useState(processedCodes.current.has(code));
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [loginState, setLoginState] = useState<LoginState>(LoginState.EnterUsername);
  const [retryLogin, setRetryLogin] = useState(false);

  const setUser = useCallback(
    (userProfile: any) => {
      loginUser(
        userDispatch,
        userSessionStorage,
        userProfile,
        setIsLoading,
        setError
      );
    },
    [userSessionStorage, userDispatch]
  );

  useEffect(() => {
    setShowNewPasswordModal(newPasswordRequired);
  }, [newPasswordRequired]);

  useEffect(() => {
    if (inputReference && inputReference.current) {
      inputReference.current.focus();
    }
  }, []);

  interface ILoginResult {
    loggedIn: boolean;
    userEmail: string;
    userPassword: string;
    userTermsAccepted?: boolean;
    language?: string;
    version?: string;
    userId?: string;
    settingNewPassword?: boolean;
  }

  // Replace the login function with Amplify Auth.signIn
  const login = async (
    userEmail: string,
    userPassword: string
  ): Promise<ILoginResult> => {
    Logger.of("Login.login").info(`for ${userEmail}`);

    try {
      // Configure for standard authentication
      configureForStandardAuth();
      const user = await Auth.signIn({ username: userEmail, password: userPassword });

      // Handle new password required
      if (user.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        setNewPasswordRequired(true);
        setIsLoading(false);
        setError(false);
        setAuthError("");

        // Return early as we need the user to set a new password
        return {
          loggedIn: false,
          settingNewPassword: true,
          userEmail,
          userPassword,
        };
      }

      Logger.of("Login.login").info("logged in successful", user);
      return {
        loggedIn: true,
        userEmail,
        userPassword,
      };
    } catch (err) {
      Logger.of("Login.login").info("got an error =>", err);
      throw err;
    }
  };

  // Handle new password submission using Amplify Auth
  const handleNewPasswordSubmit = useCallback(async () => {
    if (!newPassword) {
      return Promise.reject(false);
    }

    Logger.of("Login.handleNewPasswordSubmit").info("Setting new password");

    try {
      setIsLoading(true);

      // Complete the new password challenge
      await Auth.confirmSignIn({
        challengeResponse: newPassword
      });

      // IMPORTANT: Sign out after successful password change
      try {
        await Auth.signOut();
        Logger.of("Login.handleNewPasswordSubmit").info("Signed out after password change");

        // Show success message to user
        toastr.success("Password successfully updated. Please sign in with your new password.");
      } catch (signOutError) {
        Logger.of("Login.handleNewPasswordSubmit").warn("Error signing out after password change", signOutError);
      }

      setShowNewPasswordModal(false);
      setError(false);
      setPassword("");
      setIsLoading(false);

      // Return true to indicate success
      return true;
    } catch (error) {
      setAuthError(error.message);
      setIsLoading(false);
      Logger.of("Login.handleNewPasswordSubmit").info("error", error.message);
      toastr.error(error.message);
      return Promise.reject(error);
    }
  }, [newPassword, toastr]);

  // the password component will set a newpassword to submit
  useEffect(() => {
    if (newPassword !== "") {
      handleNewPasswordSubmit().then(() =>
        Logger.of("Login.useEffect newPassword").info("submitted")
      );
    }
  }, [handleNewPasswordSubmit, newPassword]);

  // presignup api throwing an error on success and the client will need to retry SSO
  useEffect(() => {
    try {
      if (errorQs === "server_error") {
        const jsonTxtExtract = /{.*}/;
        const results = jsonTxtExtract.exec(errorCode);
        if (results && results.index >= 0) {
          const error = JSON.parse(results[0]);
          if (error?.retryable && error?.payload?.email != null) {
            setUsername(error?.payload?.email);
            setRetryLogin(true);
          } else if (error != null && error?.retryable !== true) {
            setAuthError(displayCognitoTriggerError(error));
          }
        }
      }
    } catch (e) {
      setAuthError(e.toString());
    }
  }, [errorQs, errorCode]);


  const loginFinish = useCallback(
    async ({ loggedIn, userEmail, settingNewPassword, userPassword, userTermsAccepted }: ILoginResult) => {
      let returnUrlSearch = new Cookies().get("ReturnUrlSearch");
      const { ReturnUrl } = returnUrlSearch
        ? queryString.parse(returnUrlSearch)
        : { ReturnUrl: "" };
      if (ReturnUrl) {
        returnUrlSearch = "/";
      }

      if (loggedIn && userTermsAccepted !== true) {
        const profiles = await vrsUserActions.getUserProfileAppSync(false);
        let profile = profiles[0];
        if (profile == null) {
          console.warn("No profile found");
          profile = fallbackProfile;
        }

        const currentUser = await getCurrentUser();
        const userId = currentUser.userId || "";
        const { LanguageId: langId, Languages: languages } = profile;
        const language = languages.find(l => l.LanguageId === Number(langId));
        const { IetflanguageTag: langTag } = language;
        const normalizedTag = langTag.split('-')[0].toLowerCase();
        const termsAcceptance = await vrsUserActions.getUserTermsAcceptance(normalizedTag, userId);
        // Check terms acceptance
        if (!termsAcceptance?.acceptedDate) {
          // User hasn't accepted terms - show terms modal and stop login flow
          setShowTermsModal(true);
          setTermsContent(termsAcceptance?.content);
          // Store login data to use after terms acceptance
          setPendingLoginData({ loggedIn, userEmail, userPassword, language: termsAcceptance.language, version: termsAcceptance.version, userId })
          return;
        }
      }

      setIsLoggedIn(loggedIn);
      setError(!settingNewPassword && !loggedIn);
      setIsLoading(false);
      setPassword(userPassword);

      if (!loggedIn) {
        return;
      }
      
      // Clear processed OAuth codes when successfully logged in
      clearProcessedCodesFromStorage();

      const plantId = Utils.getPlantCookie();

      const userProfile = {
        email: userEmail,
        selectedSiteId: plantId === "0" ? "" : plantId,
      };

      setUser(userProfile);


      const searchStr = returnUrlSearch
        ? returnUrlSearch
        : window.location.search;

      const { TrafficSource, TrafficID, SFDCRecord } =
        Utils.getTrackingValuesFromQueryParameters(searchStr);

      const q: any = queryString.parse(searchStr);

      if (TrafficSource === "SFDC") {
        let accessType = "Unknown";
        if (q?.targetUrl?.includes("vrs/device")) {
          accessType = "Device";
        } else if (q?.targetUrl?.includes("vrs/sitedashboard")) {
          accessType = "Site";
        }
        try {
          const result = await vrsUserActions.saveSalesforceTrackerAppSync(
            TrafficSource,
            TrafficID,
            SFDCRecord,
            accessType
          );
          const saveResult = result?.data;
          if (saveResult) {
            console.log("saved to salesforce");
          } else if (result.error) {
            const errStr = extractErrors(result.error);
            if (errStr) {
              toastr.error(errStr);
            }
          }
        } catch (error) {
          const errStr = extractErrors(error);
          toastr.error(errStr || error);
        }
      }

      if (q.ciffName && q.ciffId && searchStr && searchStr.includes("/editor.html#")) {
        const currentDesignTemplate = `editor.html#?ciffName=${q.ciffName.replace(' ', '%20')}&ciffId=${q.ciffId.replace(
          ' ',
          '%20'
        )}`;
        window.open(currentDesignTemplate, '_self');
      } else if (q.targetUrl && searchStr) {
        navigate(`${q.targetUrl}${searchStr}`, { replace: true });
      } else if (q.targetUrl) {
        navigate(q.targetUrl, { replace: true });
      }

      removeCookie("ReturnUrlSearch", { path: "/", domain: getDomain() });
    },
    [setUser, navigate, removeCookie, toastr, vrsUserActions]
  );

  const loginWithUserDetails = async () => {
    setIsLoading(true);
    await login(username.toLowerCase().trim(), password.trim())
      .then(loginFinish)
      .catch((error) => {
        const errorMessage =
          error.message ===
            "Temporary password has expired and must be reset by an administrator."
            ? _T("Incorrect username or password.")
            : error.message;
        setAuthError(errorMessage);
        setIsLoading(false);
      });
  };

  const onNext = useCallback(
    async (bypassSSO: boolean) => {
      const SSOUrl = Utils.getSSOUrl(username);
      const searchStr = window.location.search ? window.location.hash ? `${window.location.search}${window.location.hash}` : window.location.search : "/";
      const params = queryString.parse(searchStr);
      if (params && params.targetUrl) {
        ssoTokenProvider.setIsSSO(true);
        setCookie(
          "ReturnUrlSearch",
          searchStr,
          {
            domain: getDomain(),
            expires: new Date(new Date().getTime() + 30 * 60000),
            path: "/",
            secure: true,
            encode: (value) => value, // do not encode
          }
        );

        if (params.targetUrl) {
          const { companyId, siteId } =
            Utils.getTrackingValuesFromQueryParameters(window.location.search);

          if (companyId) {
            if (
              !Utils.IsIdGuid(companyId) &&
              (siteId === "0" || siteId === "")
            ) {
              Utils.setCompanyCookie(companyId);
              companyActions.setVrsCompanyAndSiteId(companyId, "0");
            } else if (
              !Utils.IsIdGuid(companyId) &&
              siteId !== "0" &&
              siteId !== ""
            ) {
              Utils.setCompanyAndPlantCookie(companyId, siteId);
              companyActions.setVrsCompanyAndSiteId(companyId, siteId);
            }
          }
        }
      } else {
        removeCookie("ReturnUrlSearch", { path: "/", domain: getDomain() });
      }

      if (!SSOUrl || SSOUrl.length === 0 || bypassSSO) {
        ssoTokenProvider.setIsSSO(false);
        setLoginState(LoginState.EnterPassword);
      } else {
        setIsLoading(true);
        // In Amplify v6+, we directly navigate to the SSO URL
        window.open(SSOUrl, "_self");
      }
    },
    [companyActions, removeCookie, setCookie, token, username]
  );

  // simple retry login
  useEffect(() => {
    (async () => {
      if (retryLogin) {
        setRetryLogin(false);
        await onNext(false);
      }
    })();
  }, [retryLogin, onNext]);

  const onBack = async () => {
    setLoginState(LoginState.EnterUsername);
  };

  async function clearUser() {
    // User rejected terms
    setError(true);
    setAuthError("Terms must be accepted to continue");
    setIsLoading(false);
    setPendingLoginData(null);
    await logout(userDispatch);

    // Reset login state
    setLoginState(LoginState.EnterUsername);
    setUsername("");
    setPassword("");
  }

  const onCloseTermsDialog = async (hasUserAccepted: boolean) => {
    setShowTermsModal(false);
    if (hasUserAccepted && pendingLoginData) {
      // Continue with login flow using stored data
      try {
        const { version, userId, language } = pendingLoginData;
        await loginFinish({
          ...pendingLoginData,
          userTermsAccepted: true
        });
        await vrsUserActions.acceptTerms(language as string, userId as string, version as string);

        setPendingLoginData(null);
      } catch (e) {
        console.error(e);
        await clearUser();
      }
    } else {
      await clearUser();
    }
  };

  const onCloseNewPasswordDialog = async (newPassword) => {
    if (newPassword) {
      setNewPassword(newPassword);
      setIsLoading(true);

    } else {
      setNewPasswordRequired(false);
      setShowNewPasswordModal(false);
    }
  };



  // Handle OAuth callback using Amplify
  useEffect(() => {
    const module = "Login.OAUTH";

    Logger.of(module).info("code effect", {
      code,
      isLoading,
      isLoggedIn,
      error,
    });

  
    if (!code || isLoading || isLoggedIn || error || processedCodes.current.has(code)) {
      return;
    }

    setIsLoading(true);
    // Mark this code as processed immediately to prevent duplicate processing
    processedCodes.current.add(code);
    // Persist to localStorage
    saveProcessedCodesToStorage(processedCodes.current);

    // Extract the token exchange logic to a separate async function
    const exchangeCodeForTokens = async () => {
      try {
        // Tell the token provider this is an SSO login
        // Configure for SSO authentication
        configureForSSO();
        ssoTokenProvider.setIsSSO(true);

        const body = `grant_type=authorization_code&client_id=${config.cognito.APP_CLIENT_ID
          }&code=${code}&redirect_uri=${encodeURIComponent(
            Utils.getRedirectUrl(Utils.getStage())
          )}`;
        
        Logger.of(module).info(`Exchanging code for token: ${code.substring(0, 10)}...`);
        
        const { status, data } = await callExternalApi(
          "POST",
          config.cognito.OATH_TOKEN_URL + "/token",
          body,
          { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
        );

        if (status !== 200) {
          setError(true);
          setIsLoading(false);
          setAuthError(data.error);
          Logger.of(module).warn(data.error);
          return;
        }

        // Store tokens in the token provider
        ssoTokenProvider.setTokens(
          data.access_token,
          data.id_token,
          data.refresh_token,
          data.expires_in
        );

        Logger.of(module).info("Tokens stored in provider");

        // Instead of using fetchUserAttributes, decode the ID token directly
        const decodedToken: any = jwtDecode(data.id_token);

        // The ID token contains all the user attributes you need
        const email = decodedToken.email || decodedToken.preferred_username || decodedToken.sub;

        await loginFinish({
          loggedIn: true,
          userEmail: email,
          userPassword: "",
        });

        Logger.of(module).info("loginFinish TOKEN", { email });

      } catch (e) {
        Logger.of(module).warn(e);
        setError(true);
        setAuthError(e?.message || e);
        setIsLoading(false);
      }
    };

    // Execute the token exchange
    exchangeCodeForTokens();
  }, [code, loginFinish, isLoading, isLoggedIn, error]);

  const { locale } = useConfigState();

  const gridTranslationsLocale = useMemo(() => {
    const localeItem = localeMap.find(x => x.id === locale);
    if (localeItem) {
      return localeItem.dateLocale ?? {};
    } else {
      console.log(`Locale ${locale} not found in localeMap`);
    }
    return {};
  }, [locale]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={videojetConnectTheme(gridTranslationsLocale ?? {})}>
        <>
          <RootGrid container>
            <CssBaseline />
            <ImageGrid item {...loginBreaks.image} xs={false}>
              <StyledLogo id="logo" src={logo} alt="logo" />
            </ImageGrid>
            <Grid item {...loginBreaks.login} elevation={6}>
              {isLoading ? (
                <PaperContainer>
                  <CircularProgress />
                </PaperContainer>
              ) : (
                <PaperContainer>
                  {loginState === LoginState.EnterUsername && (
                    <LoginEnterUsername
                      isLoading={isLoading}
                      username={username}
                      setUsername={setUsername}
                      onNext={onNext}
                    />
                  )}
                  {loginState === LoginState.EnterPassword && (
                    <LoginEnterPassword
                      isLoading={isLoading}
                      username={username}
                      password={password}
                      setPassword={setPassword}
                      onBack={onBack}
                      loginWithUserDetails={loginWithUserDetails}
                      setAuthError={setAuthError}
                      setError={setError}
                    />
                  )}
                  <Grid container>
                    <Grid item xs>
                      <Fade in={error || !!authError}>
                        <StyledErrorTypography>
                          <EmptyItem delay={2000} text={authError ? authError : error ? _T("LoginError") : ""} />
                        </StyledErrorTypography>
                      </Fade>
                    </Grid>
                  </Grid>
                </PaperContainer>
              )}
            </Grid>
          </RootGrid>
          <TermsDialog open={showTermsModal} onClose={onCloseTermsDialog} content={termsContent} />
          {showNewPasswordModal && (
            <NewPasswordDialog
              loading={isLoading}
              open={showNewPasswordModal}
              onClose={onCloseNewPasswordDialog}
            />
          )}
        </>
      </ThemeProvider>
    </StyledEngineProvider >
  );
}

Login.displayName = "Login";

export default Login;
