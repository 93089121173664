import enGBLocale from 'date-fns/locale/en-GB';
import enUSLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import { esES as esDateLocale } from '@mui/x-data-grid-pro/locales';
import deLocale from 'date-fns/locale/de';
import { deDE as deDateLocale } from '@mui/x-data-grid-pro/locales';
import frLocale from 'date-fns/locale/fr';
import { frFR as frDateLocale } from '@mui/x-data-grid-pro/locales';
import itLocale from 'date-fns/locale/it';
import { itIT as itDateLocale } from '@mui/x-data-grid-pro/locales';
import ptBRLocale from 'date-fns/locale/pt-BR';
import { ptBR as ptBRDateLocale } from '@mui/x-data-grid-pro/locales';
import nlLocale from 'date-fns/locale/nl';
import { nlNL as nlDateLocale } from '@mui/x-data-grid-pro/locales';
import trLocale from 'date-fns/locale/tr';
import { trTR as trDateLocale } from '@mui/x-data-grid-pro/locales';
import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey('5bbcd6f1d7ce6f4c4408594dfbee0fcfTz04OTA0NCxFPTE3NDU1MTk3MjMwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=');

export const localeMap = [
  { id: 'en', locale: enGBLocale },
  { id: 'en-US', locale: enUSLocale },
  { id: 'es', locale: esLocale, dateLocale: esDateLocale },
  { id: 'de', locale: deLocale, dateLocale: deDateLocale },
  { id: 'fr', locale: frLocale, dateLocale: frDateLocale },
  { id: 'it', locale: itLocale, dateLocale: itDateLocale },
  { id: 'pt', locale: ptBRLocale, dateLocale: ptBRDateLocale },
  { id: 'nl', locale: nlLocale, dateLocale: nlDateLocale },
  { id: 'tr', locale: trLocale, dateLocale: trDateLocale },
];
