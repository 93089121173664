/**
 * Adds full path to each navigation item by combining parent and child segments
 * @param navigation - The navigation structure
 * @param parentPath - The parent path (for recursion)
 * @returns Navigation structure with fullPath property added to each item
 */
export const addFullPathsToNavigation = (navigation: any[], parentPath = '') => {
  return navigation.map(item => {
    // Skip header items or items without segments
    if (item.kind === 'header' || !item.segment) {
      return item;
    }

    // Calculate the full path for this item
    // If segment starts with a slash, it's an absolute path
    const fullPath = item.segment.startsWith('/')
      ? item.segment 
      : `${parentPath}/${item.segment}`.replace(/\/+/g, '/'); // Replace multiple slashes with single one

    // Process children recursively if they exist
    const processedItem = {
      ...item,
      fullPath,
      ...(item.children ? {
        children: addFullPathsToNavigation(item.children, fullPath)
      } : {})
    };

    return processedItem;
  });
};

/**
 * Gets all paths from the navigation structure as a flat array
 * @param navigation - The navigation structure (ideally after adding full paths)
 * @returns Array of all paths in the navigation
 */
export const getAllNavigationPaths = (navigation: any[]): string[] => {
  const paths: string[] = [];

  const extractPaths = (items: any[]) => {
    items.forEach(item => {
      if (item.fullPath) {
        paths.push(item.fullPath);
      }
      if (item.children) {
        extractPaths(item.children);
      }
    });
  };

  extractPaths(navigation);
  return paths;
};

/**
 * Finds a navigation item by its path
 * @param navigation - The navigation structure
 * @param path - The path to find
 * @returns The found navigation item or undefined
 */
export const findNavigationItemByPath = (navigation: any[], path: string): any | undefined => {
  let foundItem: any | undefined;

  const search = (items: any[]) => {
    for (const item of items) {
      if (item.fullPath === path) {
        foundItem = item;
        return;
      }
      if (item.children) {
        search(item.children);
        if (foundItem) return;
      }
    }
  };

  search(navigation);
  return foundItem;
};


export const getPathAndFirstLeafPath = (navigation: any[], segment: string): string[] => {
  // Find the item with the matching segment
  let parentItem: any | undefined;

  const findItemBySegment = (items: any[]) => {
    for (const item of items) {
      if (item.segment === segment) {
        parentItem = item;
        return;
      }
      if (item.children) {
        findItemBySegment(item.children);
        if (parentItem) return;
      }
    }
  };

  findItemBySegment(navigation);
  
  // If parent not found or has no children, return undefined
  if (!parentItem || !parentItem.children || parentItem.children.length === 0) {
    return [];
  }
  
  // Get the first child
  const firstChild = parentItem.children[0];
  
  // If first child has no children, return undefined
  if (!firstChild.children || firstChild.children.length === 0) {
    return [parentItem.fullPath, firstChild.fullPath];
  }
  
  // Return the full path of the first grandchild
  return [parentItem.fullPath, firstChild.children[0].fullPath];
};
