import { useLocation, useNavigate, useRoutes, useParams } from 'react-router';
import { ReactRouterAppProvider } from '@toolpad/core/react-router';
import type { Authentication, Navigation } from '@toolpad/core/AppProvider';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useContext, useEffect, useMemo, useState, lazy, Suspense } from 'react';
import { Navigate } from 'react-router';
// components

import SiteTagsPage from './vrspages/sitetags/SiteTagsPage/SiteTagsPage';
import { DesignEditorPage } from './pages/designeditor/DesignEditorPage';
import DesignLogoPage from './pages/design/DesignLogoPage';
import ManageSettingsPage from './pages/design/DesignSettingsPage';

// Lazy load components
const SitePage = lazy(() => import('./pages/sites/SitePage/SitePage'));
const DisplayModeSettingsSetupPage = lazy(() => import('./pages/displaymode/DisplayModeSettingsSetupPage/DisplayModeSettingsSetupPage'));
const DeviceRoleSetupPage = lazy(() => import('./pages/devicerole/DeviceRoleSetupPage/DeviceRoleSetupPage'));
const ReportsDashPage = lazy(() => import('./pages/reports/ReportsDashPage/ReportsDashPage'));
const AdminPage = lazy(() => import('./pages/admin/AdminPage/AdminPage'));
const OperationsPage = lazy(() => import('./pages/admin/OperationsPage/OperationsPage'));
const RoleSetupMainPage = lazy(() => import('./pages/role/RoleSetupMainPage/RoleSetupMainPage'));
const CompanyUserList = lazy(() => import('./pages/users/CompanyUserList/CompanyUserList'));
const VrsCompanyUserList = lazy(() => import('./vrspages/vrsusers/VrsCompanyUserList/VrsCompanyUserList'));
const VrsCompanyList = lazy(() => import('./vrspages/vrscompany/vrsCompanyList/VrsCompanyList'));
const VrsPrinterList = lazy(() => import('./vrspages/vrsprinter/vrsPrinterList/VrsPrinterList'));
const FavoritesManagement = lazy(() => import('./vrspages/favoritesmanagement/FavoritesManagement/FavoritesManagement'));
const ResManagement = lazy(() => import('./vrspages/resmanagement/ResManagement/ResManagement'));
const ResRegister = lazy(() => import('./vrspages/resregistration/Register/Register'));
const NotificationLogPage = lazy(() => import('./vrspages/notificationlog/NotificationLogPage/NotificationLogPage'));
const WorkSchedulePage = lazy(() => import('./vrspages/profileandworkschedule/WorkSchedulePage/WorkSchedulePage'));
const AlertManagement = lazy(() => import('./vrspages/alertmanagement/AlertManagement/AlertManagement'));
const ServiceDashboard = lazy(() => import('./vrspages/servicedashboard/ServiceDashboardPage/ServiceDashboardPage'));
const VrsSiteList = lazy(() => import('./vrspages/vrssite/vrsSiteList/VrsSiteList'));
const DowntimeSetupMainPage = lazy(() => import('./pages/downtime/DowntimeSetupMainPage/DowntimeSetupMainPage'));
const SiteForm = lazy(() => import('./pages/sites/SiteForm/SiteForm'));
const DowntimeCategoryManagementPage = lazy(() => import('./pages/downtime/category/DowntimeCategoryManagementPage/DowntimeCategoryManagementPage'));
const DowntimeSubCategoryManagementPage = lazy(() => import('./pages/downtime/subcategory/DowntimeSubCategoryManagementPage/DowntimeSubCategoryManagementPage'));
const DeviceInformation = lazy(() => import('./vrspages/printerInfo/DeviceInformation'));
const DeviceManagementPage = lazy(() => import('./vrspages/devicemanagement/DeviceManagementPage/DeviceManagementPage'));
const SummaryPage = lazy(() => import('./vrspages/summary/SummaryPage'));
const AvailabilityPage = lazy(() => import('./vrspages/availabilitymanagement/AvailabilityManagementPage/AvailabilityManagementPage'));
const ProductionPage = lazy(() => import('./vrspages/productionmanagement/ProductionManagementPage/ProductionManagementPage'));
const SalesforceArticleManagement = lazy(() => import('./vrspages/salesforcearticlemanagement/SalesforceArticleManagement/SalesforceArticleManagement'));
const DataTagConfigurationManagement = lazy(() => import('./vrspages/datatagconfiguration/DataTagConfigurationManagement/DataTagConfigurationManagement'));
const TranslationsManagement = lazy(() => import('./vrspages/translations/TranslationsManagement/TranslationsManagement'));
const EventConfigurationManagement = lazy(() => import('./vrspages/eventconfiguration/EventConfigurationManagement/EventConfigurationManagement'));
const ShiftManagementPage = lazy(() => import('./pages/schedulerpage/shifts/ShiftManagementPage/ShiftManagementPage'));
const LineManagementPage = lazy(() => import('./pages/linesetup/LineManagementPage/LineManagementPage'));
const ProductManagementPage = lazy(() => import('./pages/productsetup/ProductManagementPage/ProductManagementPage'));
const LineDetailPage = lazy(() => import('./pages/linepage/LineDetailPage/LineDetailPage'));
const LinesDash = lazy(() => import('./pages/dashboard/LinesDashPage/LinesDashPage'));
const ReportNotificationPage = lazy(() => import('./pages/schedulerpage/ReportNotificationPage/ReportNotificationPage'));
const GettingStarted = lazy(() => import('./exchangepages/GettingStarted/GettingStarted'));
const Explorer = lazy(() => import('./exchangepages/Explorer/Explorer'));
const LinePages = lazy(() => import('./pages/linepage/LinesPage'));
const HomePage = lazy(() => import('./pages/homepage/HomePage'));
const DesignPage = lazy(() => import('./pages/design/DesignPage'));
const DesignPageNew = lazy(() => import('./pages/design/DesignPageNew'));
const LineSetupPage = lazy(() => import('./pages/linesetup/LineSetupPage/LineSetupPage'));
const ProductSetupPage = lazy(() => import('./pages/productsetup/ProductSetupPage/ProductSetupPage'));
const SchedulerPage = lazy(() => import('./pages/schedulerpage/SchedulerPage/SchedulerPage'));
const PerformanceReport = lazy(() => import('./vrspages/reports/performancereport/PerformanceReport'));
const SnapshotReport = lazy(() => import('./vrspages/reports/snapshotreport/SnapshotReport'));
const ConsumablesReport = lazy(() => import('./vrspages/reports/consumablesreport/ConsumablesReport'));
const RapidRecoveryReport = lazy(() => import('./vrspages/reports/rapidrecoveryreport/RapidRecoveryReport'));
const UsageReport = lazy(() => import('./vrspages/reports/usagereport/UsagesReport'));
const DataDownload = lazy(() => import('./vrspages/reports/datadownload/DataDownload'));
const UnitRequiringAttentionReport = lazy(() => import('./vrspages/reports/unitrequiringattentionreport/UnitRequiringAttentionReport'));


import { AppGlobalStateContext, useAppGlobalDispatch, useAppGlobalState, useAppSiteState, useAppUserState, useConfigState, useLogOut, useVrsTranslationState } from './context/AppContext/AppContext';
import { getLinkAccessInfo } from './RouteAccess';
import UserHelper from './helpers/UserHelper';
import { addFullPathsToNavigation } from './utilities/navigationUtils';
import { getBaseNavigation } from './structure';
import { ReactComponent as CompanyLogo } from './components/Header/videojet-logo.svg';
import Utils from './utilities/utils';
import { CheckAuthorisedRoute } from './components/CheckAuthorisedRoute/CheckAuthorisedRoute';
import { ProgressIndicator } from './components/ProgressIndicator/ProgressIndicator';
import { ToolpadLayout } from './layouts/ToolpadLayout';
import { NotAuthorised } from './components/NotAuthorised/NotAuthorised';
import { NotFound } from './components/NotFound/NotFound';
import { BreadcrumbProvider } from './context/BreadcrumbContext/BreadcrumbContext';
import BreadcrumbGenerator from './components/BreadcrumbGenerator/BreadcrumbGenerator';

const queryClient = new QueryClient();

const BRANDING = {
  title: '',
  homeUrl: '/home',
  logo: <CompanyLogo style={{ width: '100%', height: '100%' }} />,
  logoAlt: 'Videojet',
};

// Lazy load components
const SiteMap = lazy(() => import('./vrspages/sitemap/SiteMap'));
const LogManagement = lazy(() => import('./vrspages/logmanagement/LogManagement'));
// ... import all other lazy components as in the original App.tsx

export const AppToolpadWrapper = () => {
  const context = useContext(AppGlobalStateContext);
  const { initialised } = useAppGlobalState();
  const { selectedSiteId, vrsAbilities } = useAppSiteState();
  const { _T } = useVrsTranslationState();
  const configState = useConfigState();
  const { userProfileLarge, userProfile, cognitoUserLoaded, isAuthenticated } = useAppUserState();

  const userDispatch = useAppGlobalDispatch();
  const logOut = useLogOut();

  const [session, setSession] = useState<any>({
    isAuthenticated,
    user: null,
    name: "",
    email: "",
    roles: [],
  });

  const { isAdmin, isPureDesignUser } = context.userState;
  const site = context.siteState.getSelectedSite();
  const isCurrentSiteExternal = configState.appDataInitialization.firstSiteData.isExternal;

  const isUserConfigImporter = !!userProfileLarge?.ListMember?.find(
    (el) => (el.Id === 'config_import' || el.Id === 'early_accessor') && el.MemberType === 'admin'
  );

  // Access info helper function
  const accessInfo = useMemo(
    () => {
      return getLinkAccessInfo({
        site,
        isAdmin,
        isPureDesignUser,
        selectedSiteId,
        vrsAbilities,
        useForAuthorizationCheck: false,
        isUserConfigImporter,
        isCurrentSiteExternal,
      });
    },
    [
      site,
      isAdmin,
      isPureDesignUser,
      selectedSiteId,
      vrsAbilities,
      isUserConfigImporter,
      isCurrentSiteExternal,
      initialised,
    ]
  );

  // Base navigation structure
  const baseNavigation = useMemo(() => {
    return getBaseNavigation(_T, selectedSiteId, isAdmin, isUserConfigImporter)
  }, [_T, selectedSiteId, isAdmin, isUserConfigImporter]);

  const NAVIGATION: Navigation = useMemo(() => {
    // Helper function to apply access control to navigation items
    const applyAccess = (item, path) => {
      const linkPath = path || `/${item.segment}`;

      // Get access information for the current item
      const access = accessInfo(linkPath);

      // Check for additional permission requirements from Sidebar.tsx logic
      let isHidden = access.hidden;

      // Check if item requires admin
      if (item.requireAdmin && !isAdmin || (item.requireSite && (selectedSiteId === 'VrsInternal' || selectedSiteId === '0' || selectedSiteId === ''))) {
        isHidden = true;
      }

      // Check for specific permission requirements
      if (item.can && !UserHelper.Can(item.can.do, item.can.do)) {
        isHidden = true;
      }


      // Check for module requirements
      const siteModuleMissing = item.siteModule && Utils.hasModuleMissing(site, item.siteModule);
      const extraModuleMissing = item.extraModule &&
        Utils.hasExtraModuleMissing(vrsAbilities.vrsInternalAccessAbilities, item.extraModule);

      const moduleMissing = !!(
        (item.siteModule && !item.extraModule && siteModuleMissing) ||
        (!item.siteModule && item.extraModule && extraModuleMissing) ||
        (item.siteModule && item.extraModule && siteModuleMissing && extraModuleMissing)
      );

      if (item.hideIfModuleNotEnabled && moduleMissing) {
        isHidden = true;
      }

      // Apply access properties
      return {
        ...item,
        disabled: !item.showAlways &&(access.disabled || moduleMissing),
        hidden: !item.showAlways && (isHidden || access.disabled || moduleMissing), // Since Toolpad doesn't support hidden or disabled, we use this property to filter out items
        // Apply to children recursively if they exist
        ...(item.children ? {
          children: item.children
            .map(child => applyAccess(child, `${linkPath}/${child.segment}`))
            .filter(child => !child.disabled && !child.hidden) // Filter out hidden and disabled children
        } : {})
      };
    };


    // Apply access control to each navigation item
    const accessControlledNavigation = initialised ? baseNavigation
      .map(item => applyAccess(item, item.segment ? `/${item.segment}` : ''))
      .filter(item => (!item.hidden || item.kind === 'header')) : [];

    // Add full paths to the navigation items
    return addFullPathsToNavigation(accessControlledNavigation);

  }, [initialised, isAuthenticated, cognitoUserLoaded, selectedSiteId, _T, accessInfo, isAdmin, isUserConfigImporter, isCurrentSiteExternal]);


  const AUTHENTICATION: Authentication | null = isAuthenticated ? {
    signIn: () => { }, // We need this property as empty function to avoid error in AppProvider
    signOut: () => { logOut(userDispatch); },
  } : null;

  useEffect(() => {
    if (isAuthenticated && userProfile) {
      setSession({
        isAuthenticated,
        user: userProfile,
        email: userProfile?.email || "",
      });
    }
  }, [isAuthenticated, userProfile]);

  const [mounted, setMounted] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      console.log('AppToolpadWrapper mounted');
    }

    return () => {
      if (mounted) {
        console.log('AppToolpadWrapper unmounted');
      }
    };
  }, [mounted]);

  const getTargetUrlAsSearch = () => {
    const pathname = window.location.pathname;
    const search = window.location.search;
    let searchWithReturnUrl = '';
    if (pathname !== 'login' && search.indexOf('targetUrl=') === -1) {
      if (!search) {
        searchWithReturnUrl = `?targetUrl=${pathname}`;
      } else {
        searchWithReturnUrl = `${search}&targetUrl=${pathname}`;
      }
    } else {
      searchWithReturnUrl = search;
    }

    return searchWithReturnUrl;
  };

  const targetUrlAsSearch = getTargetUrlAsSearch();

  // Helper function to create authorized routes
  const createAuthorizedRoute = (path, component, options: any = {}) => {
    const { extraPath = '', forceToSites = false } = options;
    const fullPath = `${path}${extraPath}`;

    return {
      path: fullPath,
      element: isAuthenticated ? (
        <Suspense fallback={<ProgressIndicator />}>
          <CheckAuthorisedRoute
            accessInfo={accessInfo}
            path={path}
            component={component}
            params={{ siteId: selectedSiteId }}
            forceToSites={forceToSites}
          />
        </Suspense>
      ) : (
        <Navigate to={`/login${targetUrlAsSearch}`} replace />
      )
    };
  };

  // Generic redirect component that can handle various redirect scenarios
  const GenericRedirect = ({ targetPath, transformParams }: { targetPath: any, transformParams?: any }) => {
    const params = useParams();

    // Apply transformation function if provided, otherwise use original params
    const finalParams = transformParams ? transformParams(params) : params;

    // Replace path parameters in the target path string
    let redirectTo = targetPath;
    Object.entries(finalParams).forEach(([key, value]) => {
      redirectTo = redirectTo.replace(`:${key}`, value);
    });

    return <Navigate to={redirectTo} replace />;
  };


  const routes = useMemo(() => !initialised ? [] : [
    {
      path: "*",
      element: <ToolpadLayout />,
      children: [
        { path: "unauthorized", element: <NotAuthorised /> },
        createAuthorizedRoute('vrs/sitemap/', <SiteMap />),
        createAuthorizedRoute('vrs/logmanagement/', <LogManagement />, { extraPath: '*' }),
        createAuthorizedRoute('configuration/remoteservice/logmanagement/', <LogManagement />, { extraPath: '*' }),
        createAuthorizedRoute('setup/site/:siteId/:setupMode', <SiteForm />),

        {
          path: "site/:siteId/lines/:lineMode",
          element: <GenericRedirect targetPath="/insight/site/:siteId/lines/:lineMode" />
        },
        {
          path: "site/:siteId/lines",
          element: <GenericRedirect targetPath="/insight/site/:siteId/lines" />
        },
        {
          path: "site/:siteId/dashboard/lines",
          element: <GenericRedirect targetPath="/insight/site/:siteId/dashboard/lines" />
        },
        {
          path: "site/:siteId/line/:id",
          element: <GenericRedirect targetPath="/insight/site/:siteId/line/:id" />
        },

        createAuthorizedRoute('insight/site/:siteId/lines/:lineMode', <LinePages />, { forceToSites: true }),
        createAuthorizedRoute('insight/site/:siteId/lines', <LinePages />, { forceToSites: true }),
        createAuthorizedRoute('insight/site/:siteId/dashboard/lines', <LinesDash />),
        createAuthorizedRoute('insight/site/:siteId/line/:id', <LineDetailPage />),

        {
          path: "site/:siteId/setup/lines",
          element: <GenericRedirect targetPath="/configuration/insight/site/:siteId/lines" />
        },

        {
          path: "site/:siteId/setup/line/:lineId/:actionMode",
          element: <GenericRedirect targetPath="/configuration/insight/site/:siteId/line/:lineId/:actionMode" />
        },


        createAuthorizedRoute('configuration/insight/site/:siteId/lines', <LineSetupPage />),
        createAuthorizedRoute('configuration/insight/site/:siteId/line/:lineId/:actionMode', <LineManagementPage />),

        createAuthorizedRoute('home', <HomePage />),
        createAuthorizedRoute('design', <DesignPage />),
        createAuthorizedRoute('new-design', <DesignPageNew />),
        createAuthorizedRoute('design-logos', <DesignLogoPage />),
        createAuthorizedRoute('design-settings', <ManageSettingsPage />),
        createAuthorizedRoute('configuration/design/logos', <DesignLogoPage />),
        createAuthorizedRoute('configuration/design/settings', <ManageSettingsPage />),
        createAuthorizedRoute('designeditor', <DesignEditorPage />),

        createAuthorizedRoute('vrs/summary', <SummaryPage />),
        createAuthorizedRoute('vrs/sitedashboard', <DeviceManagementPage />),
        createAuthorizedRoute('vrs/availability', <AvailabilityPage />),
        createAuthorizedRoute('vrs/production', <ProductionPage />),
        createAuthorizedRoute('vrs/datadownload', <DataDownload />, { extraPath: '/*' }),
        createAuthorizedRoute('vrs/workschedule', <WorkSchedulePage />),

        createAuthorizedRoute('vrs/alertmanagement', <AlertManagement />),

        createAuthorizedRoute('vrs/servicedashboard', <ServiceDashboard />),
        createAuthorizedRoute('vrs/salesforcearticles', <SalesforceArticleManagement />),
        createAuthorizedRoute('vrs/companymanagement', <VrsCompanyList />),
        createAuthorizedRoute('vrs/sitemanagement', <VrsSiteList />),
        createAuthorizedRoute('vrs/datatags', <DataTagConfigurationManagement />),
        createAuthorizedRoute('vrs/events', <EventConfigurationManagement />),

        createAuthorizedRoute('configuration/remoteservice/alertmanagement', <AlertManagement />),
        createAuthorizedRoute('configuration/remoteservice/workschedule', <WorkSchedulePage />),
        createAuthorizedRoute('configuration/remoteservice/salesforcearticles', <SalesforceArticleManagement />),
        createAuthorizedRoute('configuration/remoteservice/companymanagement', <VrsCompanyList />),
        createAuthorizedRoute('configuration/remoteservice/sitemanagement', <VrsSiteList />),
        createAuthorizedRoute('configuration/remoteservice/datatags', <DataTagConfigurationManagement />),
        createAuthorizedRoute('configuration/remoteservice/events', <EventConfigurationManagement />),

        createAuthorizedRoute('vrs/translations', <TranslationsManagement />),
        createAuthorizedRoute('admin/translations', <TranslationsManagement />),
        createAuthorizedRoute('vrs/devicemanagement', <VrsPrinterList />),
        createAuthorizedRoute('configuration/remoteservice/devicemanagement', <VrsPrinterList />),
        createAuthorizedRoute('vrs/favorites', <FavoritesManagement />),
        createAuthorizedRoute('configuration/remoteservice/favorites', <FavoritesManagement />),
        createAuthorizedRoute('vrs/notificationlog', <NotificationLogPage />),
        createAuthorizedRoute('configuration/remoteservice/notificationlog', <NotificationLogPage />),
        createAuthorizedRoute('vrs/resmanagement', <ResManagement />),
        createAuthorizedRoute('configuration/remoteservice/resmanagement', <ResManagement />),
        createAuthorizedRoute('register', Utils.isDev() ? <ResRegister /> : <NotFound />),
        createAuthorizedRoute('vrs/device/:printerId', <DeviceInformation />),


        {
          path: "site/:siteId/setup/products",
          element: <GenericRedirect targetPath="/configuration/insight/site/:siteId/products" />
        },

        {
          path: "site/:siteId/setup/product/:productId/:actionMode",
          element: <GenericRedirect targetPath="/configuration/insight/site/:siteId/product/:productId/:actionMode" />
        },

        {
          path: "site/:siteId/scheduledReport/:userId/:title/:action",
          element: <GenericRedirect targetPath="/configuration/insight/site/:siteId/product/:productId/:actionMode" />
        },

        {
          path: "site/:siteId/setup/displayMode",
          element: <GenericRedirect targetPath="/configuration/insight/site/:siteId/displayMode" />
        },

        {
          path: "site/:siteId/setup/deviceRoles",
          element: <GenericRedirect targetPath="/configuration/insight/site/:siteId/deviceRoles" />
        },

        {
          path: "site/:siteId/setup/downtimes/category/:setupParam/:action",
          element: <GenericRedirect targetPath="/configuration/insight/site/:siteId/downtimes/category/:setupParam/:action" />
        },

        {
          path: "site/:siteId/setup/downtimes/subcategory/:setupParam/:action",
          element: <GenericRedirect targetPath="/configuration/insight/site/:siteId/downtimes/subcategory/:setupParam/:action" />
        },

        createAuthorizedRoute('configuration/insight/site/:siteId/product/:productId/:actionMode', <ProductManagementPage />),
        createAuthorizedRoute('configuration/insight/site/:siteId/products', <ProductSetupPage />),
        createAuthorizedRoute('configuration/insight/site/:siteId/scheduledReport/:userId/:title/:action', <ReportNotificationPage />),
        createAuthorizedRoute('configuration/insight/site/:siteId/displayMode', <DisplayModeSettingsSetupPage />),
        createAuthorizedRoute('configuration/insight/site/:siteId/deviceRoles', <DeviceRoleSetupPage />),
        createAuthorizedRoute('configuration/insight/site/:siteId/downtimes/category/:setupParam/:action', <DowntimeCategoryManagementPage />),
        createAuthorizedRoute('configuration/insight/site/:siteId/downtimes/subcategory/:setupParam/:action', <DowntimeSubCategoryManagementPage />),

        {
          path: "site/:siteId/shifts",
          element: <GenericRedirect targetPath="/configuration/insight/site/:siteId/shifts/0" />
        },
        {
          path: "configuration/insight/site/:siteId/shifts",
          element: <GenericRedirect targetPath="/configuration/insight/site/:siteId/shifts/0" />
        },

        {
          path: "site/:siteId/shifts/:shiftPage",
          element: <GenericRedirect targetPath="/configuration/insight/site/:siteId/shifts/:shiftPage" />
        },

        {
          path: "site/:siteId/setup/shift/:id/:actionMode/:typeId/:fromSchedule",
          element: <GenericRedirect targetPath="/configuration/insight/site/:siteId/shift/:id/:actionMode/:typeId/:fromSchedule" />
        },

        {
          path: "site/:siteId/setup/shift/:id/:actionMode/:typeId",
          element: <GenericRedirect targetPath="/configuration/insight/site/:siteId/shift/:id/:actionMode/:typeId" />
        },

        {
          path: "site/:siteId/setup/downtimes",
          element: <GenericRedirect targetPath="/configuration/insight/site/:siteId/downtimes" />
        },

        {
          path: "company/users",
          element: <GenericRedirect targetPath="/configuration/insight/company/users" />
        },

        {
          path: "vrs/users",
          element: <GenericRedirect targetPath="/configuration/remoteservice/users" />
        },

        createAuthorizedRoute('configuration/insight/site/:siteId/shifts/:shiftPage', <SchedulerPage />),
        createAuthorizedRoute('configuration/insight/site/:siteId/shift/:id/:actionMode/:typeId/:fromSchedule', <ShiftManagementPage />),
        createAuthorizedRoute('configuration/insight/site/:siteId/shift/:id/:actionMode/:typeId', <ShiftManagementPage />),
        createAuthorizedRoute('configuration/insight/site/:siteId/downtimes', <DowntimeSetupMainPage />, { extraPath: '/*' }),
        createAuthorizedRoute('configuration/insight/company/users', <CompanyUserList />),

        createAuthorizedRoute('configuration/remoteservice/users', <VrsCompanyUserList />),

        // Reports
        createAuthorizedRoute('vrs/performancereport', <PerformanceReport />),
        createAuthorizedRoute('vrs/reports/datadownload', <DataDownload />, { extraPath: '/*' }),
        createAuthorizedRoute('vrs/snapshotreport', <SnapshotReport />),
        createAuthorizedRoute('vrs/rapidrecoveryreport', <RapidRecoveryReport />, { extraPath: '/*' }),
        createAuthorizedRoute('vrs/usagereport', <UsageReport />, { extraPath: '/*' }),
        createAuthorizedRoute('vrs/unitrequiringattentionreport', <UnitRequiringAttentionReport />),
        createAuthorizedRoute('vrs/consumablesreport', <ConsumablesReport />),

        createAuthorizedRoute('vrs/reports/performance', <PerformanceReport />),
        createAuthorizedRoute('vrs/reports/snapshot', <SnapshotReport />),
        createAuthorizedRoute('vrs/reports/rapidrecovery', <RapidRecoveryReport />, { extraPath: '/*' }),
        createAuthorizedRoute('vrs/reports/usage', <UsageReport />, { extraPath: '/*' }),
        createAuthorizedRoute('vrs/reports/unitrequiringattention', <UnitRequiringAttentionReport />),
        createAuthorizedRoute('vrs/reports/consumables', <ConsumablesReport />),


        {
          path: "site/:siteId/setup/roles",
          element: <GenericRedirect targetPath="/configuration/insight/site/:siteId/roles" />
        },

        createAuthorizedRoute('configuration/insight/site/:siteId/roles', <RoleSetupMainPage />, { extraPath: '/*' }),

        {
          path: "site/:siteId/report/*",
          element: <GenericRedirect targetPath="/insight/site/:siteId/report/*" />
        },

        createAuthorizedRoute('insight/site/:siteId/report/*', <ReportsDashPage />),

        {
          path: "site/:siteId/admin",
          element: <GenericRedirect targetPath="/admin/site/:siteId/properties" />
        },

        createAuthorizedRoute('admin/site/:siteId/properties', <AdminPage />),

        {
          path: "sites/operations",
          element: <GenericRedirect targetPath="/admin/operations" />
        },
        createAuthorizedRoute('admin/operations', <OperationsPage />),

        {
          path: "sites",
          element: <GenericRedirect targetPath="/configuration/insight/sites" />
        },
        createAuthorizedRoute('configuration/insight/sites', <SitePage />),

        createAuthorizedRoute('api/gettingstarted', <GettingStarted />),
        createAuthorizedRoute('api/explorer', <Explorer />),
        createAuthorizedRoute('vrs/sitetags', <SiteTagsPage />),
        createAuthorizedRoute('configuration/remoteservice/sitetags', <SiteTagsPage />),
        {
          path: '*',
          element: <NotFound />
        },
      ]
    }
  ], [initialised, isAuthenticated, cognitoUserLoaded, selectedSiteId, accessInfo, isAdmin, isUserConfigImporter, isCurrentSiteExternal]);

  // Use the useRoutes hook to create the routing element
  const routeElement = useRoutes(routes);

  // Filter navigation to only include page items for breadcrumbs
  const navigationPages = useMemo(() => {
    if (!NAVIGATION) return [];
    
    const filterPageItems = (items) => {
      if (!items) return [];
      
      return items.reduce((acc, item) => {
        // Skip headers, dividers or other non-page items
        if (item.kind === 'header' || item.kind === 'divider') {
          return acc;
        }
        
        // Add this item if it's a page
        const pageItem = { ...item };
        
        // Recursively filter children
        if (item.children && item.children.length > 0) {
          pageItem.children = filterPageItems(item.children);
        }
        
        return [...acc, pageItem];
      }, []);
    };
    
    return filterPageItems(NAVIGATION);
  }, [NAVIGATION]);

  return (
    <ReactRouterAppProvider
      navigation={NAVIGATION}
      branding={BRANDING}
      router={{
        pathname: location.pathname,
        searchParams: new URLSearchParams(location.search),
        navigate: (to: string | URL) => navigate(to)
      }}
      session={session}
      authentication={AUTHENTICATION}
    >
      <BreadcrumbProvider>
        <BreadcrumbGenerator navigation={navigationPages} />
        <QueryClientProvider client={queryClient}>
          {cognitoUserLoaded && initialised && isAuthenticated && routeElement}
        </QueryClientProvider>
      </BreadcrumbProvider>
    </ReactRouterAppProvider>
  );
}

export default AppToolpadWrapper;