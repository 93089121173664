import { useAppGlobalState } from "../../context/AppContext/AppContext";
import { NotAuthorised } from "../NotAuthorised/NotAuthorised";
import { ProgressIndicator } from "../ProgressIndicator/ProgressIndicator";
interface CheckAuthorisedRouteProps {
  path: string;
  component?: any;
  accessInfo?: (path: string) => any;
  params: {
    [key: string]: string;
  };
  children?: any;
  forceToSites?: boolean;
}

const CheckAuthorisedRoute = ({
  path,
  component,
  accessInfo,
  params,
  children,
}: CheckAuthorisedRouteProps) => {
  const accessPath = params
    ? Object.keys(params).reduce((acc, key) => {
        return acc.replace(`:${key}`, params[key]);
      }, path)
    : path;

  const { siteInitialised, initialised } = useAppGlobalState();

  const access = accessInfo ? accessInfo(`/${accessPath}`) : null;

  const isVisible = access
    ? access.hidden === undefined || !access.hidden
    : true;
  const authorised = access
    ? access.disabled === undefined || !access.disabled
    : true;

  if (component) {
    return isVisible && authorised ? (
      component
    ) : initialised ? (
      <NotAuthorised />
    ) : null;
  }
  return siteInitialised ? (
    isVisible && authorised && children ? (
      children
    ) : initialised ? (
      <NotAuthorised />
    ) : null
  ) : (
    <ProgressIndicator />
  );
};

export { CheckAuthorisedRoute };
