'use client';
import * as React from 'react';
import Stack from '@mui/material/Stack';
import Icon from '@mdi/react';
import { mdiInformationOutline, mdiAccountCircleOutline, mdiDotsVertical, mdiCogOutline } from '@mdi/js';
import Divider from '@mui/material/Divider';
import { List, useMediaQuery, useTheme, IconButton, Popover, Box } from '@mui/material';
import { useNavigate } from 'react-router';

import { useAppGlobalState, useAppSiteState, useAppUserState, useConfigState, useVrsTranslationState } from '../../context/AppContext/AppContext';
import { styled } from "@mui/material/styles";

import {
    SyncAlt as SyncAltIcon,
    SupervisedUserCircle as SupervisedUserCircleIcon,
    Extension as ExtensionIcon,
} from '@mui/icons-material';

import {
    Account,
    AccountPreview,
    AccountPreviewProps,
    AccountPopoverFooter,
    SignOutButton,
} from '@toolpad/core/Account';
import { SidebarFooterProps } from '@toolpad/core/DashboardLayout';
import { useCallback, useState } from 'react';
import { myCorporatePrimaryColor, myPrimaryColor, myWhite } from '../../constants/colors';
import { canAccessExternalVrsPage } from '../../RouteAccess';
import { ProfileMenuItem } from '../ProfileMenuItem/ProfileMenuItem';
import AboutDialog from '../AboutDialog/AboutDialog';
import EditProfileDialog from '../../vrspages/profileandworkschedule/EditProfileDialog/EditProfileDialog';
import { settingInFixPosition } from '../../constants/global';

const IconContainer = styled("div")({
    minWidth: '30px',
});


const ProfileMenuItemContainer = styled("div")(({ theme }) => ({
    display: "flex",
    padding: theme.spacing(2),
}));


const ProfileMenuLinkContainer = styled("div")({
    fontSize: 16,
    color: myPrimaryColor,
    textDecoration: "none",
    "&:hover": {
        cursor: "pointer",
    },
});

function AccountSidebarPreview(props: AccountPreviewProps & { mini: boolean }) {
    const { handleClick, open, mini } = props;
    return (
        <Stack direction="column" p={0} overflow="hidden"
            sx={{
                ...(mini && { padding: '0px !important' }),
                width: mini ? '38px !important' : '100% !important',
                '& .MuiButtonBase-root': {
                    ...(mini && { padding: '0px !important' }),
                }
            }}>
            <Divider />
            <AccountPreview
                variant={mini ? 'condensed' : 'expanded'}
                slotProps={{ avatarIconButton: { sx: mini ? { border: '0' } : {} } }}
                handleClick={handleClick}
                open={open}
            />
        </Stack>
    );
}

interface SidebarFooterAccountPopoverProps {
    mini: boolean;
    setShowEditProfileDialog: (value: boolean) => void;
    setShowAboutDialog: (value: boolean) => void;
}

function SidebarFooterAccountPopover({ mini,
    setShowEditProfileDialog,
    setShowAboutDialog }: SidebarFooterAccountPopoverProps) {
    const { _T } = useVrsTranslationState();

    const theme = useTheme();
    const { userProfile, isAdmin, userProfileLarge } = useAppUserState();

    const siteState = useAppSiteState();
    const { vrsAbilities } = siteState;
    const configState = useConfigState();

    const isUserConfigImporter = !!userProfileLarge?.ListMember?.find(
        (el) => (el.Id === 'config_import' || el.Id === 'early_accessor') && el.MemberType === 'admin'
    );

    const hasAccessVrs = useCallback(
        (requestedClaim) =>
            canAccessExternalVrsPage({
                requestedClaim,
                vrsAbilities,
                isAdmin,
                useForAuthorizationCheck: false,
                isUserConfigImporter,
                isCurrentSiteExternal: configState.appDataInitialization.firstSiteData.isExternal,
            }),
        [isAdmin, vrsAbilities, isUserConfigImporter, configState.appDataInitialization.firstSiteData.isExternal]
    );

    const mediumOrHigher = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <><Stack direction="column">
            {mini ? <AccountPreview variant="expanded" sx={{
                '& .MuiAvatar-root': {
                    backgroundColor: myCorporatePrimaryColor,
                    '& .MuiSvgIcon-root': {
                        color: '#fff',
                        '& path': {
                            fill: '#fff',
                        }
                    },
                },

                '& > .MuiStack-root': {
                    '& > .MuiStack-root': {
                        color: myCorporatePrimaryColor,
                        '& .MuiBox-root': {
                            '& svg': { // Updated selector to target SVG elements directly
                                color: '#fff',
                                '& path': {
                                    fill: '#fff', // Ensures the SVG path fill is white
                                }
                            }
                        }
                    }
                }
            }} /> : null}
            {!mediumOrHigher && (
                <ProfileMenuItemContainer>
                    <ProfileMenuLinkContainer>{userProfile && userProfile.email}</ProfileMenuLinkContainer>
                </ProfileMenuItemContainer>
            )}
            <List component="nav" aria-label="profile menu">
                {hasAccessVrs('edit-profile') && (
                    <ProfileMenuItem
                        icon={
                            <IconContainer>
                                <Icon path={mdiAccountCircleOutline} size={1} />
                            </IconContainer>
                        }
                        linkLabel={_T('Profile')}
                        onClickMenuItem={() => setShowEditProfileDialog(true)}
                        testId="profile-btn"
                    />
                )}
                <ProfileMenuItem
                    icon={
                        <IconContainer>
                            <Icon path={mdiInformationOutline} size={1} />
                        </IconContainer>
                    }
                    linkLabel={_T('About')}
                    onClickMenuItem={() => setShowAboutDialog(true)}
                    testId="about-btn"
                />
            </List>
            <Divider />
            <AccountPopoverFooter sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <SignOutButton />
            </AccountPopoverFooter>
        </Stack>
        </>
    );
}

// Settings component related functions and components
function SettingsPreview({ handleClick, mini }) {
    const { _T } = useVrsTranslationState();
    return (
        <Stack
            direction="row"
            alignItems="center"
            onClick={handleClick}
            sx={{
                cursor: 'pointer',
                color: myWhite,
                ...(mini && { padding: '4px' }),
                padding: '2px 0px',
                ...(mini && { padding: '4px 0px' }),
                margin: '0px 0px',
                width: '100%',
                justifyContent: mini ? 'center' : 'flex-start',
                display: 'flex',
            }}
        >
            <IconContainer sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: !mini ? '18px' : '2px' }}>
                <Icon path={mdiCogOutline} size={1.5} />
            </IconContainer>
            {!mini && <span style={{ color: myWhite, marginLeft: '16px', fontSize: '12px' }}>{_T('Settings')}</span>}
            {!mini && <Box style={{ flexGrow: 0.92 }}></Box>}
            {!mini && <IconButton
                size="small"
                sx={{
                    color: myWhite,
                    ...(mini && { padding: '4px' }),
                }}
            >
                <Icon path={mdiDotsVertical} size={1} />
            </IconButton>
            }
        </Stack>
    );
}

interface SettingsPopoverProps {
    mini: boolean;
    selectedSiteId: string;
    handleSettingsClose: () => void;
}

function SettingsPopover({ handleSettingsClose, selectedSiteId }: SettingsPopoverProps) {
    const { _T } = useVrsTranslationState();
    const navigate = useNavigate();
    return (
        <Stack direction="column">
            <List component="nav" aria-label="settings menu">
                {selectedSiteId && selectedSiteId !== "0" && selectedSiteId !== 'vrsOperate' && <ProfileMenuItem
                    icon={
                        <IconContainer>
                            <SupervisedUserCircleIcon />
                        </IconContainer>
                    }
                    linkLabel={_T('Admin Site Configuration')}
                    onClickMenuItem={() => { navigate(`/admin/site/${selectedSiteId}/properties`); handleSettingsClose(); }}
                    testId="settings-btn"
                />}
                <ProfileMenuItem
                    icon={
                        <IconContainer>
                            <SyncAltIcon />
                        </IconContainer>
                    }
                    linkLabel={_T('Manage Translation')}
                    onClickMenuItem={() => {
                        navigate("/admin/translations");
                        handleSettingsClose();
                    }}
                    testId="settings-btn"
                />
                <ProfileMenuItem
                    icon={
                        <IconContainer>
                            <ExtensionIcon />
                        </IconContainer>
                    }
                    linkLabel={_T('Operations')}
                    onClickMenuItem={() => {
                        navigate("/admin/operations");
                        handleSettingsClose();
                    }}
                    testId="app-settings-btn"
                />
            </List>
        </Stack>
    );
}

const createSettingsPreviewComponent = (mini: boolean) => {
    function PreviewComponent(props) {
        return <SettingsPreview {...props} mini={mini} />;
    }
    return PreviewComponent;
};

const createSettingsPopoverComponent = (mini: boolean, handleSettingsClose: () => void, selectedSiteId: string) => {
    function PopoverComponent() {
        return <SettingsPopover mini={mini} selectedSiteId={selectedSiteId} handleSettingsClose={handleSettingsClose} />;
    }
    return PopoverComponent;
};

const createPreviewComponent = (mini: boolean) => {
    function PreviewComponent(props: AccountPreviewProps) {
        return <AccountSidebarPreview {...props} mini={mini} />;
    }
    return PreviewComponent;
};

const createPopoverComponent = (mini: boolean, setShowAboutDialog, setShowEditProfileDialog) => {
    function PopoverComponent() {
        return <SidebarFooterAccountPopover mini={mini}
            setShowAboutDialog={setShowAboutDialog}
            setShowEditProfileDialog={setShowEditProfileDialog} />;
    }
    return PopoverComponent;
};

const FooterContainer = styled("div")(({ theme }) => ({
    flexShrink: 0,
    padding: theme.spacing(0, 1.5),
    position: 'fixed',
    bottom: 0,
    zIndex: 3000,
    backgroundColor: theme.palette.background.paper,
}));

export default function SidebarFooterAccount({ mini }: SidebarFooterProps) {
    const [showAboutDialog, setShowAboutDialog] = useState(false);
    const [showEditProfileDialog, setShowEditProfileDialog] = useState(false);
    const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);

    const { selectedSiteId } = useAppSiteState();
    const { isAdmin } = useAppGlobalState();

    const onCloseAboutDialog = useCallback(() => {
        setShowAboutDialog(false);
    }, []);

    const onCloseEditProfileDialog = useCallback(() => {
        setShowEditProfileDialog(false);
    }, []);


    const handleSettingsClose = () => {
        setSettingsAnchorEl(null);
    };

    const PreviewComponent = React.useMemo(() => createPreviewComponent(mini), [mini]);
    const PopoverComponent = React.useMemo(() => createPopoverComponent(mini, setShowAboutDialog, setShowEditProfileDialog), [mini]);
    const SettingsPreviewComponent = React.useMemo(() => createSettingsPreviewComponent(mini), [mini]);
    const SettingsPopoverComponent = React.useMemo(() => createSettingsPopoverComponent(mini, handleSettingsClose, selectedSiteId), [mini]);

    const handleSettingsClick = (event) => {
        setSettingsAnchorEl(event.currentTarget);
    };


    const settingsOpen = Boolean(settingsAnchorEl);

    return (
        <>
            <FooterContainer sx={{
                color: myWhite,
                backgroundColor: '#2f2f2f',
                border: `1px solid #2f2f2f`,
                width: mini ? 'auto' : '350px',
                '& .MuiAvatar-root': {
                    backgroundColor: myWhite,
                    width: '32px',
                    height: '32px',
                    '& svg': {
                        color: myCorporatePrimaryColor,
                        '& path': {
                            fill: myCorporatePrimaryColor,
                        }
                    }
                },
                '& .MuiButtonBase-root': {
                    '& svg': {
                        color: myWhite,
                        '& path': {
                            fill: myWhite,
                        }
                    }
                },
                '& .MuiIconButton-sizeSmall': {
                    '& .MuiAvatar-circular': {
                        '& svg': {
                            color: myWhite,
                            '& path': {
                                fill: myCorporatePrimaryColor,
                            }
                        }
                    }
                }
            }}>
                {isAdmin && settingInFixPosition &&<Stack direction="column" alignItems={mini ? "center" : "flex-end"} sx={{ mb: 1 }}>
                    <SettingsPreviewComponent handleClick={handleSettingsClick} />
                    <Popover
                        open={settingsOpen}
                        anchorEl={settingsAnchorEl}
                        onClose={handleSettingsClose}
                        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        slotProps={{
                            paper: {
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: (theme) =>
                                        `drop-shadow(0px 2px 8px ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.10)' : 'rgba(0,0,0,0.32)'})`,
                                    mt: 1,
                                    '&::before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        bottom: 10,
                                        left: 0,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translate(-50%, -50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            },
                        }}
                    >
                        <SettingsPopoverComponent />
                    </Popover>
                </Stack>
                }

                <Account
                    slots={{
                        preview: PreviewComponent,
                        popoverContent: PopoverComponent,
                    }}
                    slotProps={{
                        popover: {
                            transformOrigin: { horizontal: 'left', vertical: 'top' },
                            anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                            slotProps: {
                                paper: {
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: (theme) =>
                                            `drop-shadow(0px 2px 8px ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.10)' : 'rgba(0,0,0,0.32)'})`,
                                        mt: 1,
                                        '&::before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            bottom: 10,
                                            left: 0,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translate(-50%, -50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                },
                            },
                        },
                    }}
                />
            </FooterContainer>
            {showAboutDialog && <AboutDialog open={showAboutDialog} onClose={onCloseAboutDialog} />}
            {showEditProfileDialog && <EditProfileDialog open={showEditProfileDialog} onClose={onCloseEditProfileDialog} />}

        </>
    );
}

export function ToolbarAccountOverride() {
    return null;
}