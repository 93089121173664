import { useEffect, useState, useCallback, useMemo, useContext } from 'react';
import { cognitoFields } from 'vccm-common';
import { Navigate, createBrowserRouter, RouterProvider } from 'react-router';
import { checkIsAdmin, isVrsSuperUser, isPureDesignUser as checkIsPureDesignUser, authUser, getCurrentUser, signOutUser, userRefreshSession, getUserIdAttributes, hasIdentities, checkIsCSEUser, checkIsServiceUser, checkIsVJInternalUser, getAuthClaims } from './libs/authLib';
import { setAbilitiesForSite, useSiteActions } from './actions/siteActions';
import { ssoTokenProvider } from './utilities/SSOTokenProvider';
import { configureForSSO } from './amplifyConfig';
import { Logger } from "./utilities/Logger/Logger";

import React from 'react';
import { styled } from '@mui/material/styles';
import { AppToolpadWrapper } from './AppToolpadWrapper';
import { ProgressIndicator } from './components/ProgressIndicator/ProgressIndicator';
import { getBaseNavigation } from './structure';
import { addFullPathsToNavigation, getPathAndFirstLeafPath } from './utilities/navigationUtils';
import { getLinkAccessInfo } from './RouteAccess';
import { InfoDialog } from './components/InfoDialog/InfoDialog';
import Utils from './utilities/utils';
import Login from './components/Login/Login';
import UserHelper from './helpers/UserHelper';
import { AppGlobalStateContext, restoreUserFromSession, useAppGlobalDispatch, useAppSiteState, useAppUserState, useConfigState, useLogOut, useVrsTranslationState } from './context/AppContext/AppContext';
import { APP_INITIALISED, APP_INITIALISED_WITH_VRS_OPERATIONS } from './actions/actionTypes';
import { useCompanyActions } from './actions/companyActions';
import { useUserActions } from './actions/userActions';
import { useConfigActions } from './actions/configActions';
import { vrsOperationsSite } from './constants/global';
import { LayoutProvider } from './context/LayoutContext/LayoutContext';

const InfoContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});


const getTargetUrlAsSearch = () => {
  const pathname = window.location.pathname;
  const search = window.location.search;
  let searchWithReturnUrl = '';
  if (pathname !== 'login' && search.indexOf('targetUrl=') === -1) {
    if (!search) {
      searchWithReturnUrl = `?targetUrl=${pathname}`;
    } else {
      searchWithReturnUrl = `${search}&targetUrl=${pathname}`;
    }
  } else {
    searchWithReturnUrl = search;
  }

  return searchWithReturnUrl;
};

export default function App() {
  const { isAuthenticated } = useAppUserState();
  const { selectedSiteId, vrsAbilities } = useAppSiteState();
  const dispatch = useAppGlobalDispatch();

  const siteActions = useSiteActions();
  const companyActions = useCompanyActions();
  const userActions = useUserActions();
  const configActions = useConfigActions();

  const configState = useConfigState();
  const { loadVrsTranslations, loadVrsUserProfile, _T } = useVrsTranslationState();

  const logOut = useLogOut();
  const { cognitoUserLoaded } = useAppUserState();

  const [retrievingError] = useState(false);
  const [keepAliveTimer, setKeepAliveTimer] = useState<ReturnType<typeof setInterval> | null>(null);
  const [keepAliveUpdate] = useState(0);

  const [mounted, setMounted] = useState<boolean>(false);

  const authKeepAlive = useCallback(() => {
    (async () => {
      Logger.of('App.authKeepAlive').trace('auth keep alive', new Date().toISOString(), { isAuthenticated });
      if (isAuthenticated && !(await authUser())) {
        Logger.of('App.authKeepAlive').warn('User became unauthenticated');
        await logOut(dispatch);
      }
    })();
  }, [isAuthenticated, logOut, dispatch]);

  const setCompanyAndPlants = useCallback(
    (companyId, plantId) => {
      companyActions.setVrsCompanyAndSiteId(companyId, plantId);
    },
    [companyActions]
  );

  const checkQueryStringForCompanyAndSiteId = useCallback(async () => {
    let activeCompanyId;
    let activeSiteId;

    const { companyId, siteId } = Utils.getTrackingValuesFromQueryParameters(window.location.search);

    if (companyId) {
      // Check company and site Access

      if (Utils.IsIdGuid(companyId) || companyId === '0') {
        if (siteId === 'VrsInternal' || siteId === '0' || siteId === '') {
          setCompanyAndPlants('0', '0');
          activeCompanyId = 0;
          activeSiteId = 0;
        } else {
          companyActions.setVrsCompanyAndSiteId('0', siteId);
          activeCompanyId = 0;
          activeSiteId = siteId;
        }
      } else {
        if (siteId === 'VrsInternal' || siteId === '0' || siteId === '' || Utils.IsIdGuid(siteId)) {
          const company = await companyActions.getCompanyAppSync(companyId);
          configActions.setHasCompanyAccess(company !== null);
          if (company) {
            setCompanyAndPlants(companyId, '0');
          } else {
            setCompanyAndPlants('0', '0');
          }
          activeCompanyId = companyId;
          activeSiteId = 0;
        } else {
          const company = await companyActions.getCompanyAppSync(companyId);
          const site = await companyActions.getPlantAppSync(siteId);
          configActions.setHasCompanyAccess(company !== null);
          configActions.setHasSiteAccess(site !== null);

          if (company && site) {
            setCompanyAndPlants(companyId, siteId);
            activeSiteId = siteId;
          } else if (company && !site) {
            setCompanyAndPlants(companyId, '0');
            activeSiteId = 0;
          } else {
            setCompanyAndPlants('0', '0');
            activeSiteId = 0;
          }
          activeCompanyId = companyId;
        }
      }
    } else {
      activeCompanyId = Utils.getCompanyCookie() || '0';
      activeSiteId = Utils.getPlantCookie() || '0';
      if (!Utils.IsIdGuid(activeSiteId)) {
        setCompanyAndPlants(activeCompanyId, activeSiteId);
      }
    }

    configActions.setQueryStringChecked(true);

    return {
      activeCompanyId,
      activeSiteId,
    };
  }, [companyActions, configActions, setCompanyAndPlants]);

  // initialization
  useEffect(() => {
    const initializeAuth = async () => {
      try {
        // Check if we have SSO tokens in storage
        const hasStoredTokens = await ssoTokenProvider.initializeAuthSession();

        if (hasStoredTokens) {
          // Configure Amplify to use the token provider
          configureForSSO();
          console.log("Restored SSO session from stored tokens");
        }

        if (await authUser()) {
          console.log('restoring user....');
          if (window.location.pathname.includes('/welcome/vrs/user')) {
            Logger.of('App').trace('trying to migrate user');
            await signOutUser();
          }

          const currentUser = await getCurrentUser();

          const { activeSiteId } = await checkQueryStringForCompanyAndSiteId();

          if (currentUser) {
            const idToken = await userRefreshSession();
            if (idToken) {
              const tokenDecoded = Utils.parseJwt(idToken);

              const userProfile = {
                email: tokenDecoded.email || currentUser['email'] || UserHelper.getEmailAddressBySession(currentUser),
                selectedSiteId: activeSiteId === '0' ? '' : activeSiteId,
              };

              restoreUserFromSession(dispatch, userProfile);
              Logger.of('App.Initialise').trace('Current user is =>', currentUser);
            }
          }
        }

        userActions.setCognitoUserLoaded(true);
      } catch (e) {
        console.error("Error initializing auth", e);
      }
    };

    initializeAuth();
    return () => {
      // cleanup
      if (keepAliveTimer != null) {
        clearInterval(keepAliveTimer);
        setKeepAliveTimer(null);
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    authKeepAlive();
  }, [keepAliveUpdate, authKeepAlive]);

  const createSite = useCallback(
    (currentUser) => {
      Logger.of('App.createSite').info('Creating site');
      siteActions
        .createSite({
          id: currentUser.username,
          title: 'My Site',
          companyId: currentUser.username,
          subscriptionStatus: 'trial',
          modules: ['Design'],
        })
        .then(async (site) => {
          if (site) {
            Logger.of('App.createSite').info('Site created:', site);
            Logger.of('App.createSite').info('Refreshing user session:', currentUser);
            // refreshing the token will get any updates that may have happened on Cognito fields due to new site access
            const refreshUser = await userRefreshSession();
            const sites = await siteActions.loadSites();
            Logger.of('App.createSite').info('current:', {
              currentUser,
              refreshUser,
            });
            await siteActions.setSite(site.id, currentUser);
            const targetSite = sites.find((s) => s.id === site.id);
            if (targetSite) {
              companyActions.setVrsCompanyId(site.companyId ? targetSite.companyId : '');
            }

            const vrsAbilities = currentUser ? await Utils.checkVrsAbilities(currentUser) : {};

            dispatch({
              type: APP_INITIALISED,
              payload: {
                selectedSiteId: site.id,
                currentUser,
                isAdmin: await checkIsAdmin() || (await isVrsSuperUser()),
                isPureDesignUser: await checkIsPureDesignUser(),
                isIdentityUser: await hasIdentities(),
                isCSEUser: await checkIsCSEUser(),
                isServiceUser: await checkIsServiceUser(),
                isVJInternalUser: await checkIsVJInternalUser(),
                authClaims: await getAuthClaims(),
                vrsAbilities,
              },
            });
          } else {
            Logger.of('App.createSite').warn('Could not create site');
          }
        })
        .catch(async (error) => {
          if (error && error.message && error.message.includes('Already exists')) {
            const sites = await siteActions.loadSites();
            // we use self signed user id as newly created site
            const siteId = currentUser.username;
            await siteActions.setSite(siteId, currentUser);
            const targetSite = sites.find((site) => site.id === siteId);
            if (targetSite) {
              companyActions.setVrsCompanyId(targetSite.companyId ? targetSite.companyId : '');
            }

            const vrsAbilities = currentUser ? await Utils.checkVrsAbilities(currentUser) : {};

            dispatch({
              type: APP_INITIALISED,
              payload: {
                selectedSiteId: siteId,
                currentUser,
                isAdmin: await checkIsAdmin() || (await isVrsSuperUser()),
                isPureDesignUser: await checkIsPureDesignUser(),
                isIdentityUser: await hasIdentities(),
                isCSEUser: await checkIsCSEUser(),
                isServiceUser: await checkIsServiceUser(),
                isVJInternalUser: await checkIsVJInternalUser(),
                authClaims: await getAuthClaims(),
                vrsAbilities,
              },
            });
          } else {
            Logger.of('App.createSite').warn('Could not create site', error);
          }
        });
    },
    [siteActions, companyActions, dispatch]
  );

  const initiateSiteLoading = useCallback(() => {
    (async () => {

      const currentUser: any = await getCurrentUser();
      let userSite: any = null;
      let sites: Array<any> = [];
      try {
        await checkQueryStringForCompanyAndSiteId();
        if (selectedSiteId && selectedSiteId !== '0') {
          userSite = await siteActions.loadSingleSite(selectedSiteId);
          const vrsSite = await companyActions.getPlantAppSync(selectedSiteId);
          if (userSite) {
            configActions.setAppDataInitialization('firstSiteData', (state) => ({
              ...state,
              loaded: true,
              isExternal: vrsSite?.isExternal,
            }));
            companyActions.setVrsCompanyId(userSite.companyId);
            if (selectedSiteId !== '0') {
              companyActions.setVrsCompanySiteId(selectedSiteId);
            }
            sites = [userSite];
          }
        }
      } catch (err) {
        Logger.of('App.initiateSiteLoading').warn('User does not have a site', err);
      }

      const tokenDecoded = await getUserIdAttributes();
      Logger.of('App.initiateSiteLoading').trace('decoded id token', tokenDecoded);
      if (tokenDecoded.authClaims) {
        console.log(JSON.stringify(JSON.parse(tokenDecoded.authClaims), null, 2));
      }

      const isadmin = await checkIsAdmin() || (await isVrsSuperUser());
      const vrsAbilities = currentUser ? await Utils.checkVrsAbilities(currentUser) : {};

      const vrsInternalAccessAbilities = currentUser ? await Utils.checkVrsInternalAccess(currentUser) : [];

      let siteId = '';

      if (isadmin || vrsInternalAccessAbilities.length > 0 || Object.keys(vrsAbilities).length > 0) {
        const localeStr = await loadVrsUserProfile(false);
        const activeLocal = localeStr || navigator.language.split(/[-_]/)[0];
        configActions.setLocale(activeLocal);
        await loadVrsTranslations(activeLocal, false);
      }

      if (!selectedSiteId && (await checkIsPureDesignUser())) {
        try {
          const userSite = await siteActions.loadSingleSite(currentUser.username);

          if (userSite) {
            configActions.setAppDataInitialization('firstSiteData', (state) => ({
              ...state,
              loaded: true,
            }));
            sites = [userSite];
            siteId = sites[0].id;
          }
        } catch (err) {
          Logger.of('App.initiateSiteLoading').warn('User does not have a site', err);
        }
      }

      if (sites !== undefined && sites.length > 0) {
        siteId = sites[0].id;
        if (currentUser && siteId) {
          Logger.of('App.initiateSiteLoading').trace('Current user is =>', currentUser);

          await siteActions.setSite(siteId, currentUser);
        }

        if (vrsInternalAccessAbilities.length > 0) {
          dispatch({
            type: APP_INITIALISED_WITH_VRS_OPERATIONS,
            payload: {
              selectedSiteId: siteId,
              currentUser,
              isAdmin: await checkIsAdmin() || (await isVrsSuperUser()),
              isPureDesignUser: await checkIsPureDesignUser(),
              vrsInternalAccessAbilities,
              vrsAbilities,
              isIdentityUser: await hasIdentities(),
              isCSEUser: await checkIsCSEUser(),
              isServiceUser: await checkIsServiceUser(),
              isVJInternalUser: await checkIsVJInternalUser(),
              authClaims: await getAuthClaims(),
            },
          });
        } else {
          dispatch({
            type: APP_INITIALISED,
            payload: {
              selectedSiteId: siteId,
              currentUser,
              isAdmin: await checkIsAdmin() || (await isVrsSuperUser()),
              isPureDesignUser: await checkIsPureDesignUser(),
              vrsAbilities,
              isIdentityUser: await hasIdentities(),
              isCSEUser: await checkIsCSEUser(),
              isServiceUser: await checkIsServiceUser(),
              isVJInternalUser: await checkIsVJInternalUser(),
              authClaims: await getAuthClaims(),
            },
          });
        }
      } else {
        // Check VRS Operations Access
        const vrsInternalAccessAbilities = currentUser ? await Utils.checkVrsInternalAccess(currentUser) : [];
        if (vrsInternalAccessAbilities.length > 0) {
          const vrsAbilities = currentUser ? await Utils.checkVrsAbilities(currentUser) : {};

          if (currentUser) {
            try {
              Logger.of('Setting vrsInternal site').info('Token decoded during this feature', tokenDecoded);
              setAbilitiesForSite('VrsInternal', tokenDecoded, configActions);
              dispatch({
                type: APP_INITIALISED_WITH_VRS_OPERATIONS,
                payload: {
                  selectedSiteId: vrsOperationsSite.id,
                  siteInitialised: true,
                  vrsInternalAccessAbilities,
                  vrsAbilities,
                  currentUser,
                  isAdmin: await checkIsAdmin() || (await isVrsSuperUser()),
                  isPureDesignUser: await checkIsPureDesignUser(),
                  isIdentityUser: await hasIdentities(),
                  isCSEUser: await checkIsCSEUser(),
                  isServiceUser: await checkIsServiceUser(),
                  isVJInternalUser: await checkIsVJInternalUser(),
                  authClaims: await getAuthClaims(),
                },
              });
            } catch (err) {
              // Prompt the user to reauthenticate by hand...?
              Logger.of('Setting vrsInternal').warn('Error getting abilities', err);
              return null;
            }
          } else {
            dispatch({
              type: APP_INITIALISED_WITH_VRS_OPERATIONS,
              payload: {
                selectedSiteId: vrsOperationsSite.id,
                siteInitialised: true,
                vrsInternalAccessAbilities,
                vrsAbilities,
                currentUser,
                isAdmin: await checkIsAdmin() || (await isVrsSuperUser()),
                isPureDesignUser: await checkIsPureDesignUser(),
                isIdentityUser: await hasIdentities(),
                isCSEUser: await checkIsCSEUser(),
                isServiceUser: await checkIsServiceUser(),
                isVJInternalUser: await checkIsVJInternalUser(),
                authClaims: await getAuthClaims(),
              },
            });
          }
        } else if (selectedSiteId !== '0' && tokenDecoded && !tokenDecoded[cognitoFields.COMPANY_ID_LIST]) {
          // create a site since we don't have any.
          // This means we must be a self sign-up user
          await createSite(currentUser);
        } else {
          let selectedSiteId = '0';
          if (!(await checkIsAdmin()) && !(await isVrsSuperUser())) {
            const sites = await siteActions.loadSites();
            const site = sites.length > 0 ? sites[0] : null;
            if (site !== null) {
              const refreshUser = await userRefreshSession();
              Logger.of('App.createSite').info('current:', {
                currentUser,
                refreshUser,
              });
              await siteActions.setSite(site.id, currentUser);
              const targetSite = sites.find((s) => s.id === site.id);
              if (targetSite) {
                companyActions.setVrsCompanyId(targetSite.companyId ? targetSite.companyId : '');

                Utils.setCompanyCookie(targetSite.companyId ? targetSite.companyId : '0');
              }

              selectedSiteId = site.id;
            }
          }

          const vrsAbilities = currentUser ? await Utils.checkVrsAbilities(currentUser) : {};
          dispatch({
            type: APP_INITIALISED,
            payload: {
              selectedSiteId,
              currentUser,
              isAdmin: await checkIsAdmin() || (await isVrsSuperUser()),
              isPureDesignUser: await checkIsPureDesignUser(),
              isIdentityUser: await hasIdentities(),
              isCSEUser: await checkIsCSEUser(),
              isServiceUser: await checkIsServiceUser(),
              isVJInternalUser: await checkIsVJInternalUser(),
              authClaims: await getAuthClaims(),
              vrsAbilities,
            },
          });
        }
      }
    })();
  }, [
    selectedSiteId,
    createSite,
    companyActions,
    siteActions,
    configActions,
    dispatch,
    loadVrsTranslations,
    loadVrsUserProfile,
    checkQueryStringForCompanyAndSiteId,
  ]);

  useEffect(() => {
    (async () => {
      if (isAuthenticated && !configState.appDataInitialization.firstSiteData.initiated) {
        configActions.setAppDataInitialization('firstSiteData', (state) => ({
          ...state,
          initiated: true,
        }));
        userActions.setCognitoUserLoaded(true);
        initiateSiteLoading();
      }
    })();
  }, [
    isAuthenticated,
    keepAliveTimer,
    userActions,
    initiateSiteLoading,
    configActions,
    configState.appDataInitialization.firstSiteData.initiated,
  ]);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      console.log('Application mounted');
    }

    return () => {
      if (mounted) {
        console.log('Application unmounted');
      }
    };
  }, [mounted]);

  // Debug authentication state
  useEffect(() => {
    console.log("Authentication state changed:", { isAuthenticated, cognitoUserLoaded });
  }, [isAuthenticated, cognitoUserLoaded]);

  const targetUrlAsSearch = getTargetUrlAsSearch();
  const { userProfileLarge } = useAppUserState();
  const context = useContext(AppGlobalStateContext);
  const { isAdmin, isPureDesignUser } = context.userState;
  const site = context.siteState.getSelectedSite();
  const isCurrentSiteExternal = configState.appDataInitialization.firstSiteData.isExternal;

  const isUserConfigImporter = !!userProfileLarge?.ListMember?.find(
    (el) => (el.Id === 'config_import' || el.Id === 'early_accessor') && el.MemberType === 'admin'
  );

  // Access info helper function
  const accessInfo = useMemo(
    () =>
      getLinkAccessInfo({
        site,
        isAdmin,
        isPureDesignUser,
        selectedSiteId,
        vrsAbilities,
        useForAuthorizationCheck: false,
        isUserConfigImporter,
        isCurrentSiteExternal,
      }),
    [
      site,
      isAdmin,
      isPureDesignUser,
      selectedSiteId,
      vrsAbilities,
      isUserConfigImporter,
      isCurrentSiteExternal
    ]
  );

  const baseNavigation = useMemo(() => {
    return getBaseNavigation(_T, selectedSiteId, isAdmin, isUserConfigImporter)
  }, [_T, selectedSiteId, isAdmin, isUserConfigImporter]);

  const allowedNavigation = useMemo(() => {
    // Helper function to apply access control to navigation items
    const applyAccess = (item, path) => {
      const linkPath = path || `/${item.segment}`;
      const access = accessInfo(linkPath);

      // Check for additional permission requirements from Sidebar.tsx logic
      let isHidden = access.hidden;

      // Check if item requires admin
      if (item.requireAdmin && !isAdmin || (item.requireSite && (selectedSiteId === 'VrsInternal' || selectedSiteId === '0' || selectedSiteId === ''))) {
        isHidden = true;
      }

      // Check for specific permission requirements
      if (item.can && !UserHelper.Can(item.can.do, item.can.do)) {
        isHidden = true;
      }

      // Check for module requirements
      const siteModuleMissing = item.siteModule && Utils.hasModuleMissing(site, item.siteModule);
      const extraModuleMissing = item.extraModule &&
        Utils.hasExtraModuleMissing(vrsAbilities.vrsInternalAccessAbilities, item.extraModule);

      const moduleMissing = !!(
        (item.siteModule && !item.extraModule && siteModuleMissing) ||
        (!item.siteModule && item.extraModule && extraModuleMissing) ||
        (item.siteModule && item.extraModule && siteModuleMissing && extraModuleMissing)
      );

      if (item.hideIfModuleNotEnabled && moduleMissing) {
        isHidden = true;
      }

      // Apply access properties
      return {
        ...item,
        disabled: access.disabled || moduleMissing,
        hidden: isHidden || access.disabled || moduleMissing, // Since Toolpad doesn't support hidden or disabled, we use this property to filter out items
        // Apply to children recursively if they exist
        ...(item.children ? {
          children: item.children
            .map(child => applyAccess(child, `${linkPath}/${child.segment}`))
            .filter(child => !child.hidden) // Filter out hidden children
        } : {})
      };
    };

    // Apply access control to each navigation item
    const accessControlledNavigation = baseNavigation
      .map(item => applyAccess(item, item.segment ? `/${item.segment}` : ''))
      .filter(item => !item.hidden || item.kind === 'header');

    // Add full paths to the navigation items
    return addFullPathsToNavigation(accessControlledNavigation);

  }, [selectedSiteId, _T, accessInfo, isAdmin, isUserConfigImporter, isCurrentSiteExternal]);


  const router = useMemo(() => {
    // Helper function to extract all segment IDs from navigation structure
    const extractAllSegmentIds = (navItems): string[] => {
      const segmentIds = new Set<string>();

      const extractSegments = (items) => {
        if (!items) return;

        items.forEach(item => {
          if (item.segment && !item.hidden && item.children && item.children.length > 0) {
            segmentIds.add(item.segment);
          }

          if (item.children) {
            extractSegments(item.children);
          }
        });
      };

      extractSegments(navItems);
      return Array.from(segmentIds);
    };

    const userNavigationItems: any[] = [
      // Login routes
      ...[
        "/login/:token",
        "/login",
        `/login${targetUrlAsSearch}`,
      ].map((path) => ({
        path,
        element: isAuthenticated ?
          <Navigate to="/home" replace /> :
          <Login />
      })),
      {
        path: "/",
        element: isAuthenticated ?
          <Navigate to="/home" replace />
          : cognitoUserLoaded ? (
            <Navigate to={`/login${targetUrlAsSearch}`} replace />
          ) : (
            <ProgressIndicator fullScreen={true} />
          )
      },
      ...extractAllSegmentIds(allowedNavigation).map((segmentId) => {
        const [fullPath, targetLeafFullPath] = getPathAndFirstLeafPath(allowedNavigation, segmentId);
        return {
          path: fullPath,
          element: isAuthenticated ?
            <Navigate to={targetLeafFullPath ? targetLeafFullPath : fullPath} replace />
            : cognitoUserLoaded ? (
              <Navigate to={`/login${targetUrlAsSearch}`} replace />
            ) : (
              <ProgressIndicator fullScreen={true} />
            )
        }
      }),
      {
        path: "*",
        Component: AppToolpadWrapper,
      }
    ];

    return createBrowserRouter(userNavigationItems);
  }, [isAuthenticated, cognitoUserLoaded, targetUrlAsSearch, allowedNavigation, selectedSiteId]);

  // Force router to re-render when auth state changes
  const [routerKey, setRouterKey] = useState(0);
  useEffect(() => {
    // This will force RouterProvider to re-render completely when auth state changes
    setRouterKey(prev => prev + 1);
  }, [isAuthenticated, cognitoUserLoaded]);


  return (
    <React.StrictMode>
      <LayoutProvider>
        {cognitoUserLoaded && <RouterProvider key={routerKey} router={router} />}
        {retrievingError && (
          <InfoDialog
            title={_T('Warning')}
            open={retrievingError}
            onClose={() => {
              window.location.reload();
            }}
            content={
              <InfoContainer>
                <div>{_T('Retrieving Issue')}</div>
              </InfoContainer>
            }
          />
        )}
      </LayoutProvider>
    </React.StrictMode>
  );
}
