import React from 'react';
import { useBanners } from '../../hooks/useBanner';
import Banner from './Banner';

const BannerContainer: React.FC = () => {
  const { getBannersByPriority } = useBanners();
  
  const sortedBanners = getBannersByPriority();

  if (sortedBanners.length === 0) {
    return null;
  }

  return (
    <>
      {sortedBanners.map((banner, index) => (
        <Banner key={banner.id} banner={banner} index={index} />
      ))}
    </>
  );
};

export default BannerContainer;
