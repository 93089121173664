import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
  mySecondaryColor,
  mySecondaryColorDimmed,
} from "../../constants/colors";
import { styled } from "@mui/material/styles";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: '2px 20px',

  marginBottom: theme.spacing(1),
  textDecoration: "none",
  "&:hover, &:focus": {
    backgroundColor: mySecondaryColorDimmed,
  },
  minWidth: '140px'
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: mySecondaryColor,
  transition: theme.transitions.create("color"),
  width: 24,
  display: "flex",
  cursor: "pointer",
  justifyContent: "center",
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    padding: 0,
    color: mySecondaryColor,
    cursor: "pointer",
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: "1.06rem",
  }
}));
export interface IProfileMenuItemProps {
  icon: any;
  linkLabel: string;
  onClickMenuItem: () => void;
  testId: string;
}

const ProfileMenuItem = ({
  icon,
  linkLabel,
  onClickMenuItem,
  testId
}: IProfileMenuItemProps) => {


  return <StyledListItem
    onClick={onClickMenuItem}>
    <StyledListItemIcon>
      {icon}
    </StyledListItemIcon>
    <StyledListItemText
      primary={linkLabel}
      data-testid={testId} />
  </StyledListItem>

}

export { ProfileMenuItem };
