
import { Box, LinearProgress } from '@mui/material';
import { styled } from "@mui/material/styles";

const RootContainer = styled("div")(({ theme }) => ({
  width: "340px",
  position: "absolute",
  bottom: theme.spacing(0.625),
}));

const LinearProgressContainer = styled("div")(({ theme }) => ({
  width: "100%",
  position: "absolute",
  top: 5,
  height: theme.spacing(2),
}));


const SimpleLinearProgress = () => {
  return <Box sx={{ width: '100%', height: '5px', backgroundColor: 'black' }}>
    <RootContainer>
      <LinearProgressContainer>
        <LinearProgress sx={{ height: '5px' }} />
      </LinearProgressContainer>
    </RootContainer>
  </Box>
}

export { SimpleLinearProgress };

